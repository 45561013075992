import { addDaysToDate } from './date';

export const getNextDateGenerator = {
    annually: getNextAnnualDate,
    biweekly: getNextBiWeekDate,
    daily: getNextDayDate,
    monthly: getNextMonthDate,
    none: date => new Date(date),
    quarterly: getNextQuarterDate,
    semi_annual: getNextSemiAnnualDate,
    weekly: getNextWeekDate
};

function getNextDayDate(date, unit = 1) {
    const nextDayDate = new Date(date);
    nextDayDate.setDate(nextDayDate.getDate() + 1 * unit);
    return nextDayDate;
}

function getNextWeekDate(date, unit = 1) {
    const nextWeekDate = new Date(date);
    nextWeekDate.setDate(nextWeekDate.getDate() + 7 * unit);
    return nextWeekDate;
}

function getNextBiWeekDate(date, unit = 1) {
    const nextBiWeekDate = new Date(date);
    nextBiWeekDate.setDate(nextBiWeekDate.getDate() + 14 * unit);
    return nextBiWeekDate;
}

function getNextMonthDate(date, unit = 1) {
    const nextMonthDate = new Date(date);
    return toValidDate({
        day: nextMonthDate.getDate(),
        month: nextMonthDate.getMonth() + 1 * unit,
        year: nextMonthDate.getFullYear()
    });
}

function getNextQuarterDate(date, unit = 1) {
    const nextQuarterDate = new Date(date);
    return toValidDate({
        day: nextQuarterDate.getDate(),
        month: nextQuarterDate.getMonth() + 3 * unit,
        year: nextQuarterDate.getFullYear()
    });
}

function getNextSemiAnnualDate(date, unit = 1) {
    const nextSemiAnnualDate = new Date(date);

    return toValidDate({
        day: nextSemiAnnualDate.getDate(),
        month: nextSemiAnnualDate.getMonth() + 6 * unit,
        year: nextSemiAnnualDate.getFullYear()
    });
}

export function getNextAnnualDate(date, unit = 1) {
    const nextAnnualDate = new Date(date);
    nextAnnualDate.setFullYear(nextAnnualDate.getFullYear() + 1 * unit);
    return nextAnnualDate;
}

export function getLastDateOfTheMonth(year, month) {
    return new Date(year, month + 1, 0);
}

export function toValidDate({ day, month, year }) {
    const lastDateOfTheMonth = getLastDateOfTheMonth(year, month);
    const currentDate = new Date(year, month, day);

    return lastDateOfTheMonth.getDate() < day
        ? lastDateOfTheMonth
        : currentDate;
}

export function transformDateToBusinessDate(dueDate) {
    let validDueDate = new Date(dueDate);

    // Check if the new date falls on a weekend (Saturday: 6, Sunday: 0)
    if (validDueDate.getDay() === 6) {
        // Move to the prev Friday
        validDueDate.setDate(validDueDate.getDate() - 1);
    } else if (validDueDate.getDay() === 0) {
        // Move to the prev Friday
        validDueDate.setDate(validDueDate.getDate() - 2);
    }

    return validDueDate;
}

export function getFutureRecurrenceDate({
    startDate,
    recurrenceType,
    unit = 1
}) {
    const getNextDate = getNextDateGenerator[recurrenceType];
    return getNextDate(startDate, unit);
}

export function getFutureRecurrenceDates({
    endDate,
    recurrenceType,
    startDate
}) {
    const recurrenceDates = [];
    let unit = 1;

    let currentRecurrenceDate = getFutureRecurrenceDate({
        recurrenceType,
        startDate,
        unit
    });

    while (currentRecurrenceDate <= endDate) {
        unit += 1;
        recurrenceDates.push(currentRecurrenceDate);
        currentRecurrenceDate = getFutureRecurrenceDate({
            recurrenceType,
            startDate,
            unit
        });
    }

    return recurrenceDates;
}

export function getLastDueDate(dueDate) {
    return getNextAnnualDate(new Date(dueDate));
}

export function computeRecurrenceFutureDueDate({
    daysBeforeDueDate,
    dueDate,
    recurrences = [],
    recurrenceType
}) {
    // compute the upcoming due date based on recurrence type

    const nextDueDates = getFutureRecurrenceDates({
        endDate: getLastDueDate(new Date(dueDate)),
        recurrenceType,
        startDate: new Date(dueDate)
    });

    // sort the recurrences by due date ascending

    const sortedRecurrences = [...recurrences].sort((a, b) => {
        return new Date(a.dueDate) - new Date(b.dueDate);
    });
    const sortedNextDueDates = nextDueDates.sort((a, b) => a - b);

    const nextRecurrenceDueDates = sortedRecurrences.map(
        (recurrence, index) => {
            const { dueDate: futureDueDate, startDate: futureStartDate } =
                getRecurenceDates({
                    daysBeforeDueDate,
                    dueDate: sortedNextDueDates[index]
                });

            return {
                futureDueDate,
                futureStartDate,
                id: recurrence.id,
                originalDueDate: new Date(recurrence.dueDate),
                originalStartDate: new Date(recurrence.startDate)
            };
        }
    );

    return nextRecurrenceDueDates;
}

export function updateRecurrenceDates({
    daysBeforeDueDate,
    dueDate,
    recurrences = [],
    recurrenceType
}) {
    // sort the recurrences by due date ascending
    const sortedRecurrences = [...recurrences].sort((a, b) => {
        return new Date(a.dueDate) - new Date(b.dueDate);
    });

    const startDate = new Date(dueDate);

    const nextRecurrenceDueDates = sortedRecurrences.map(
        (recurrence, index) => {
            const nextDueDate = getFutureRecurrenceDate({
                recurrenceType,
                startDate,
                unit: index + 1
            });

            return {
                ...recurrence,
                ...getRecurenceDates({
                    daysBeforeDueDate,
                    dueDate: nextDueDate
                })
            };
        }
    );

    return nextRecurrenceDueDates;
}

function getRecurenceDates({ daysBeforeDueDate, dueDate }) {
    const futureDueDate = transformDateToBusinessDate(dueDate);
    const futureStartDate = addDaysToDate(futureDueDate, -daysBeforeDueDate);

    return {
        dueDate: futureDueDate,
        startDate: futureStartDate
    };
}

export function draftRecurrenceDates({
    daysBeforeDueDate,
    endDate,
    greaterThanDueDate,
    recurrenceType,
    startDate
}) {
    let nextRecurrenceDueDates = getFutureRecurrenceDates({
        endDate,
        recurrenceType,
        startDate
    });

    if (greaterThanDueDate) {
        const filterDate = new Date(greaterThanDueDate);
        nextRecurrenceDueDates = nextRecurrenceDueDates.filter(
            date => date > filterDate
        );
    }

    nextRecurrenceDueDates = nextRecurrenceDueDates.map(nextDueDate =>
        getRecurenceDates({ daysBeforeDueDate, dueDate: nextDueDate })
    );

    return nextRecurrenceDueDates;
}

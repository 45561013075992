export const getRecurrenceTypeFilterName = schema =>
    `${schema}_recurrence_type_filter`;
export const getActivityStatusFilterName = schema =>
    `${schema}_activity_status_filter`;
export const getActivityTypeFilterName = schema =>
    `${schema}_activity_type_filter`;
export const getActivityEntityFilterName = schema =>
    `${schema}_activity_entity_filter`;
export const getActivityReviewersFilterName = schema =>
    `${schema}_activity_reviewer_filter`;
export const getActivityAssigneesFilterName = schema =>
    `${schema}_activity_assignee_filter`;

const getActivitiesFiltersBySchemaGql = ({ schema }) => {
    return {
        [getRecurrenceTypeFilterName(schema)]: {
            count: true,
            recurrence_type: true
        },
        [getActivityStatusFilterName(schema)]: {
            count: true,
            status: true
        },
        [getActivityTypeFilterName(schema)]: {
            count: true,
            name: true
        },
        [getActivityEntityFilterName(schema)]: {
            count: true,
            name: true
        },
        [getActivityReviewersFilterName(schema)]: {
            count: true,
            full_name: true
        },
        [getActivityAssigneesFilterName(schema)]: {
            count: true,
            full_name: true
        }
    };
};

export const getActivitiesFiltersGql = ({ schemas = [] }) => {
    const querySchemas = schemas.reduce((res, schema) => {
        return {
            ...res,
            ...getActivitiesFiltersBySchemaGql({
                schema
            })
        };
    }, {});

    return {
        query: {
            __name: 'getActivitiesFilters',
            ...querySchemas
        }
    };
};

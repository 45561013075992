import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import CloseMenuListItem from 'components/CloseMenuListItem';

import useConfig from 'hooks/useConfig';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const CloseActivityMenuListItem = ({ activityId, activitySchema, groups, onBeforeClose, onClick, referencedUsers, userId }) => {
    const [closedActivity, closeActivity, reset] = useData(activitySchema);
    const {useActivities} = useGlobalStateHooks();
    const [_activities, setActivities] = useActivities();

    const { HOME } = useConfig();

    const navigate = useNavigate();

    const handleClose = useCallback(async() => {
        if(onBeforeClose) await onBeforeClose();
        let userToClose;
        let closedAssignmentType;
        loop1:
        for (let assignmentType in referencedUsers.current){
            const {users: assignmentUsers, status} = referencedUsers.current[assignmentType];
            if (status === 'inprogress'){
                for (let user of assignmentUsers){
                    if (user?.lastName === 'Group' && groups.current.includes(user.id)){
                        userToClose = user;
                        closedAssignmentType = assignmentType;
                        break loop1;
                    }else if (user.id === Number(userId)){
                        userToClose = user;
                        closedAssignmentType = assignmentType;
                        break loop1;
                    }
                }
            }
        }

        const dataToSubmit = {
            activityId,
            status: 'complete'
        }
        if (userToClose?.lastName === 'Group'){
            dataToSubmit.groupId = userToClose.id;
        }else{
            dataToSubmit.userId = userToClose.id;
        }
        dataToSubmit.assignmentType = closedAssignmentType;
        dataToSubmit.userName = `${userToClose.firstName} ${userToClose.lastName}`;
        await closeActivity(dataToSubmit);
    }, [activityId, closeActivity, groups, onBeforeClose, referencedUsers, userId]);

    useEffect(() => {
        if (!closedActivity) {
            return;
        }
        setActivities({});

        if (isFunction(onClick)) {
            onClick(closedActivity);
        }

        setTimeout(() => {
            navigate(HOME);
        }, 1000);

        reset();
    }, [HOME, closedActivity, navigate, onClick, reset, setActivities]);

    return <CloseMenuListItem onClick={handleClose} />;
};

export default CloseActivityMenuListItem;

import AddMarketingQuestions from 'components/AddMarketingQuestions';
import AttachmentList from 'components/AttachmentList';
import CheckboxWithLabel from 'components/CheckboxWithLabel';
import ContentBox from 'components/ContentBox';
import DatePicker from 'components/DatePicker';
import Dropdown from 'components/Dropdown';
import FileUpload from 'components/FileUpload';
import Form from 'components/Form';
import TextareaInput from 'components/TextareaInput';
import TextInput from 'components/TextInput';
import WithLabel from 'components/WithLabel';
import { useEffect, useState } from 'react';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';
import titleCase from 'utilities/titleCase';

const MarketingRequestForm = props => {
    const {
        className = '',
        dropdownSelections,
        dropdowns,
        fileUploader,
        form,
        forms,
        handleForm,
        handleVariableError,
        handleVariableSubmit,
        handleVariableValid,
        handleVariableValidPre,
        instructions,
        onDelete,
        onUpdate,
        providedSchema,
        questions=[],
        request = {},
        setQuestions,
        setUpdatedQuestions,
        updatedQuestions,
        version
    } = props;
    const { useTenants, useUserSetSchema } = useGlobalStateHooks();
    
    const [tenants] = useTenants();
    const [_userSetSchema, setUserSetSchema] = useUserSetSchema();

    const [file, setFile] = useState([])

    const {
        description,
        dueDate = '',
        expectedUseDate = '',
        highImportance,
        name,
        recipient
    } = request;

    const {
        DESCRIPTION,
        DUE_DATE,
        EXPECTED_USE_DATE,
        HIGH_IMPORTANCE,
        INSTRUCTIONS,
        RECIPIENT,
        REQUEST_NAME,
        SELECTED_FILES
    } = useTranslation();

    // const navigate = useNavigate();

    useEffect(() => {
        if(version?.file){
            const files = [];
            function prepFileNames(file){
                const namePrefix = file
                    .slice(0, file.lastIndexOf('_'))
                    .replaceAll('_', ' ')
                    .trim();
                const fileExtension = file.slice(file.lastIndexOf('.') + 1);
                const fileName = `${namePrefix}.${fileExtension}`;
                return {file, fileName}
            }
            const versionFiles = version?.file.split(", ");
            for(const vf of versionFiles){
                const res = prepFileNames(vf);
                if (!version.deleted) {
                    files.push({
                        name: version.fileName,
                        uploaded_timestamp: version?.uploaded_timestamp,
                        url: res?.file
                    })
                }
            }
            setFile(files);
        }
    }, [setFile, version]);

    const setUpFiles = (newName, isDeleted) => {
        if(version?.file){
            const files = [];
            function prepFileNames(file){
                const namePrefix = file
                    .slice(0, file.lastIndexOf('_'))
                    .replaceAll('_', ' ')
                    .trim();
                const fileExtension = file.slice(file.lastIndexOf('.') + 1);
                const fileName = `${namePrefix}.${fileExtension}`;
                return {file, fileName}
            }
            const versionFiles = version?.file.split(", ");
            for(const vf of versionFiles){
                const res = prepFileNames(vf);
                if (!version.deleted && !isDeleted) {
                    files.push({
                        name: newName,
                        uploaded_timestamp: version?.uploaded_timestamp,
                        url: res?.file
                    })
                }
            }
            setFile(files);
        }
    }

    const onDeleteOverride = async (params) => {
        await onDelete(params);
        setUpFiles(params.filename, true);
    }

    const onUpdateOverride = async (params) => {
        await onUpdate(params);
        setUpFiles(params.newName);
    }

    const setTenant = selectedTenant => {
        setUserSetSchema(selectedTenant);
    }

    const { handleError, handleSubmit, handleValid } = form;

    return (
        <Form className={`${className} ${styles.form}`}>
            <ContentBox
                className={styles.instructions}
                html={instructions}
                title={INSTRUCTIONS}
            />

            <TextInput
                className={styles.name}
                isRequired={true}
                label={REQUEST_NAME}
                onChange={handleValid('requestName')}
                onError={handleError('requestName')}
                onSubmit={handleSubmit('requestName')}
                placeholder={REQUEST_NAME}
                value={name}
            />

            {tenants.length > 1 && !providedSchema &&
                <Dropdown
                    className={styles.name}
                    isRequired={true}
                    items={tenants.map(tenant => ({label: titleCase(tenant), value: tenant}))}
                    label="Tenant"
                    onChange={setTenant}
                    onError={handleError('tenant')}
                    onSubmit={handleSubmit('tenant')}
                    onValid={handleValid('tenant')}
                />
            }

            <DatePicker
                className={styles.dueDate}
                isRequired={true}
                label={DUE_DATE}
                onChange={handleValid('dueDate')}
                placeholder={(dueDate) ? new Date(dueDate).toISOString().split('T')[0] : 'YYYY-MM-DD'}
            />

            <DatePicker
                className={styles.useDate}
                isRequired={true}
                label={EXPECTED_USE_DATE}
                onChange={handleValid('expectedUseDate')}
                placeholder={(expectedUseDate) ? new Date(expectedUseDate).toISOString().split('T')[0] : 'YYYY-MM-DD'}
            />

            <TextInput
                className={styles.recipient}
                isRequired={true}
                label={RECIPIENT}
                onChange={handleValid('recipient')}
                onError={handleError('recipient')}
                onSubmit={handleSubmit('recipient')}
                value={recipient}
            />

            {dropdowns && dropdowns.map(({ title, options }, index) => {
                const selectedItem = (Array.isArray(dropdownSelections)) ? dropdownSelections[index] : "";
                const values = options.map(a => a.value);
                const selectedIndex = values.indexOf(selectedItem);
                return (
                    <Dropdown
                        items={options}
                        label={title}
                        onError={handleError(`dropdown${index}`)}
                        onSubmit={handleSubmit(`dropdown${index}`)}
                        onValid={handleValid(`dropdown${index}`)}
                        selected={selectedIndex}
                    />
                );
            })}

            {forms && Object.keys(forms).length > 0 && (
                <AddMarketingQuestions
                    // marketingId={id}
                    className={styles.addQuestions}
                    handleError={handleVariableError}
                    handleForm={handleForm}
                    handleSubmit={handleVariableSubmit}
                    handleValid={handleVariableValid}
                    handleValidPre={handleVariableValidPre}
                    questions={questions}
                    setQuestions={setQuestions}
                    setUpdatedQuestions={setUpdatedQuestions}
                    updatedQuestions={updatedQuestions}
                />
            )}

            <CheckboxWithLabel
                className={styles.checkboxWithLabel}
                isChecked={highImportance}
                onChange={handleValid('highImportance')}
                text={HIGH_IMPORTANCE}
            />

            <TextareaInput
                className={styles.description}
                label={DESCRIPTION}
                onChange={handleValid('description')}
                rows={6}
                text={description}
            />

            {(Object.keys(request).length < 1) &&
                <FileUpload filesList={fileUploader} providedSchema={providedSchema} />
            }

            {(version?.file) &&
                <WithLabel className={styles.withLabel} text={SELECTED_FILES}>
                    <AttachmentList
                        hasEditPermission={true}
                        items={file}
                        onDelete={onDeleteOverride}
                        onUpdate={onUpdateOverride}
                        providedSchema={providedSchema}
                    />
                </WithLabel>
            }
        </Form>
    );
};

export default MarketingRequestForm;

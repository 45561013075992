import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';
import { usePermissions } from 'hooks/usePermissions';

import Buttons from 'components/Buttons';
import BackButton from 'components/BackButton';
import CloseCaseButton from 'components/CloseCaseButton';
import CloseCaseDirectButton from 'components/CloseCaseDirectButton';
import InfoHollowButton from 'components/InfoHollowButton';

const OPTIONS_TO_CLOSE_CASE = ['case:optionsClose'];

const CaseActions = props => {
    const {
        caseId,
        caseRecord,
        className = '',
        onBeforeClose,
        isClosed,
        onReopenCase
    } = props;

    const { CASE_MANAGEMENT_ROUTE } = useConfig();

    const { hasPermissions } = usePermissions();

    const hasPermissionInOptionsToClose = hasPermissions(OPTIONS_TO_CLOSE_CASE);

    const navigate = useNavigate();

    const handleBack = useCallback(() => {
        navigate(CASE_MANAGEMENT_ROUTE);
    }, [CASE_MANAGEMENT_ROUTE, navigate]);

    return (
        <Buttons className={className}>
            <BackButton onClick={handleBack} />

            {isClosed ? (
                <InfoHollowButton onClick={onReopenCase} text={'Reopen'} />
            ) : hasPermissionInOptionsToClose ? (
                <CloseCaseButton
                    caseId={caseId}
                    caseRecord={caseRecord}
                    onBeforeClose={onBeforeClose}
                />
            ) : (
                <CloseCaseDirectButton caseId={caseId} />
            )}
        </Buttons>
    );
};

export default CaseActions;

import { useEffect } from 'react';

import DocumentManagementRepositoryPanel from 'components/DocumentManagementRepositoryPanel';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useRepository from './hooks/useRepository';
import useCaseData from './hooks/useCaseData';
import useActivityData from './hooks/useActivityData';

import normalizeData from './data';
import {useSetAllRepositoriesData} from 'components/DocumentManagementRepositoryTab/hooks/useAllRepositoryData';
import Loader from 'components/Loader';

const DocumentManagementRepositoryTab = () => {
    const { useSchema, useTenants, useQuerySchemas } = useGlobalStateHooks();

    const setAllRepositoriesData = useSetAllRepositoriesData();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [querySchemas] = useQuerySchemas();

    const [repositories, setRepositories] = useRepository();
    const { loading: caseLoading, error: caseError, data: caseData } = useCaseData();
    const { loading: activityLoading, error: activityError, data: activityData } = useActivityData();

    useEffect(() => {
        const selectedTenant = tenants && tenants.length === 1 ? tenants[0] : schema;
        if (caseData && activityData) {
            const normalizedData = normalizeData({
                activityData,
                caseData,
                querySchemas,
                schema: selectedTenant,
                tenants
            });

            if (JSON.stringify(repositories) !== JSON.stringify(normalizedData)) {
                setRepositories(normalizedData);
                setAllRepositoriesData(normalizedData);
            }
        }
        //eslint-disable-next-line
    }, [caseData, activityData, schema, tenants, repositories, setRepositories, setAllRepositoriesData]);

    if (caseError){
    }

    if (activityError){
    }

    if(tenants && tenants.length===1){
        return <DocumentManagementRepositoryPanel items={repositories} loading={caseLoading || activityLoading} schema={schema} />;
    }else{
        return <Loader text={'Please select only one tenant to view a list of documents.'} />
    }
};

export default DocumentManagementRepositoryTab;
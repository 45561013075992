import { memo } from 'react';

import Label from 'components/Label';
import WithCheckboxDynamicReturn from 'components/WithCheckboxDynamicReturn';

import styles from './styles.module.scss';

const CheckboxWithLabelDynamicReturn = ({
    className = '',
    isChecked,
    isDisabled = false,
    onChange,
    text = ''
}) => {
    return (
        <div className={`${className} ${styles.checkboxWithLabel} ${isDisabled ? styles.disabled : ''}`}>
            <WithCheckboxDynamicReturn
                isChecked={isChecked}
                isDisabled={isDisabled}
                label={text}
                onChange={isDisabled ? () => {} : onChange}
            >
                <Label className={styles.label} text={text} />
            </WithCheckboxDynamicReturn>
        </div>
    );
};

export default memo(CheckboxWithLabelDynamicReturn);

import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import CloseMenuListItem from 'components/CloseMenuListItem';

import useConfig from 'hooks/useConfig';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';

const CloseCaseMenuListItem = ({ caseId, onBeforeClose, onClick }) => {
    const [closedCase, closeCase, reset] = useData();

    const { CASE_MANAGEMENT_ROUTE } = useConfig();

    const navigate = useNavigate();

    const handleClose = useCallback(async () => {
        if(onBeforeClose) await onBeforeClose();
        await closeCase({
            caseId
        });
    }, [caseId, closeCase]);

    useEffect(() => {
        if (!closedCase) {
            return;
        }

        if (isFunction(onClick)) {
            onClick(closedCase);
        }

        navigate(CASE_MANAGEMENT_ROUTE);

        reset();
    }, [CASE_MANAGEMENT_ROUTE, closedCase, navigate, onClick, reset]);

    

    return <CloseMenuListItem onClick={handleClose} />;
};

export default CloseCaseMenuListItem;

import { useRef, useState } from 'react';

import Comment from 'components/Comment';
import CommentInput from 'components/CommentInput';
import ErrorMessage from 'components/ErrorMessage';
import SectionWithBorder from 'components/SectionWithBorder';
import Title from 'components/Title';

import useForm from 'hooks/useForm';

import useConstants from './hooks/useTranslation';

import styles from './styles.module.scss';
import isFunction from 'utilities/isFunction';
import { useGetCommentUserTagging } from 'queries/users/users.query';

const VersionedComments = props => {
    const {
        className = '',
        commentType,
        commentTypeId,
        commentTypeName,
        comments = {},
        onDelete,
        onSubmit,
        onUpdate,
        usedSchema,
        user
    } = props;

    const { COMMENTS } = useConstants();

    const commentRef = useRef();

    const [errorMessage, setErrorMessage] = useState();

    const { handleError, handleForm, handleValid } = useForm({
        comment: ''
    });

    const { data: commentUsersAndGroups = [] } = useGetCommentUserTagging({
        usedSchema
    });

    const handleCommentsSubmit = () => {
        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }
        if (isFunction(onSubmit)) {
            onSubmit(output);
        }
    };

    return (
        <SectionWithBorder className={`${styles.comments} ${className}`}>
            <Title text={COMMENTS} />

            <CommentInput
                commentType={commentType}
                commentTypeId={commentTypeId}
                commentTypeName={commentTypeName}
                commentUsersAndGroups={commentUsersAndGroups}
                onError={handleError('comment')}
                onSubmit={handleCommentsSubmit}
                onValid={handleValid('comment')}
                ref={commentRef}
                usedSchema={usedSchema}
                user={user}
            />

            <ErrorMessage className={styles.errorMessage} text={errorMessage} />

            <div className={styles.verticalScrollContainer}>
                {/* <div className={styles.verticalScroll}> */}

                {Object.keys(comments)
                    .sort((a, b) => b - a)
                    .map((key, index) => {
                        const sortedComments = comments[key].sort(
                            (objA, objB) =>
                                objA.createdAt < objB.createdAt ? 1 : -1
                        );
                        return (
                            <>
                                <Title text={`Version ${key}`} />

                                {sortedComments.map(
                                    ({
                                        content,
                                        createdAt,
                                        deleted,
                                        id,
                                        user
                                    }) => {
                                        return (
                                            <Comment
                                                commentId={id}
                                                content={content}
                                                createdAt={createdAt}
                                                deleted={deleted}
                                                key={`${id}${index}`}
                                                onDelete={onDelete}
                                                onUpdate={onUpdate}
                                                user={user}
                                            />
                                        );
                                    }
                                )}
                            </>
                        );
                    })}

                {/* </div> */}
            </div>
        </SectionWithBorder>
    );
};

export default VersionedComments;

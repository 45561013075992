import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { buildGqlQuery, gqlClient, gqlSortTypes } from 'utilities/gql/gql.util';
import {
    closeActivityMutationGql,
    getActivitiesGql,
    getActvitiesQueryKey,
    getAllActivitiesGql
} from './activities.gql';
import { queryKeys } from './keys';
import {
    activitiesFilterDataTransformer,
    computeUsersData,
    getActivityRecurrences,
    getCompletable,
    getEntitiesName,
    isActivityActive,
    getPolicyReferences
} from 'utilities/activities/dataTransformer';
import { getActivitiesFiltersGql } from './activities-filters.gql';
import { queryClient } from './client';

export const groupActivitiesByStatus = activities => {
    const results = {};

    activities.forEach(activity => {
        const { status } = activity;
        if (!results[status]) {
            results[status] = [];
        }
        results[status].push(activity);
    });

    return results;
};

export const formatSimpleReportActivities = activities => {
    return activities.map(activity => {
        return {
            active: activity.active,
            assigned: activity.assigned,
            closedDate: activity.closed_date,
            completable: activity.completable,
            dueDate: activity.due_date,
            entities: activity.entities,
            flagged: activity.flagged,
            id: activity.id,
            isHierarchical: activity.isHierarchical,
            recurrence: activity.recurrence,
            reviewer: activity.reviewer,
            riskLabel: activity.risk_label,
            schema: activity.schema,
            sortDate: activity.due_date,
            startDate: activity.start_date,
            status: activity.status,
            text: activity.name,
            type: activity.activity_type.name
        };
    });
};

const activityStatusSort = {
    completed: 4,
    overdue: 1,
    pending: 2,
    upcoming: 3
};

const sortActitivies = (objA, objB) => {
    if (activityStatusSort[objA.status] > activityStatusSort[objB.status])
        return 1;
    if (activityStatusSort[objA.status] < activityStatusSort[objB.status])
        return -1;
    if (objA.sortDate > objB.sortDate) return 1;
    if (objA.sortDate < objB.sortDate) return -1;
    if (objA.type > objB.type) return 1;
    if (objA.type > objB.type) return 1;
    if (objA.type < objB.type) return -1;
    if (objA.text > objB.text) return 1;
    if (objA.text < objB.text) return -1;
    return 0;
};

const isAssignedToMeFilter =
    ({ userId }) =>
    ({ assigned = [], is_hierarchical, reviewer = [], status }) => {
        userId = Number(userId);

        const isAssignedToMe = assigned.some(({ id }) => id === userId);
        const isReviewedByMe = reviewer.some(({ id }) => id === userId);

        if (!isAssignedToMe && !isReviewedByMe) {
            return false;
        }

        const assigment = assigned.find(x => x.id === userId);

        const isAssignedToMeAndIsNotAHierarchical =
            isAssignedToMe &&
            !is_hierarchical &&
            assigment?.groupStatus !== 'complete' &&
            assigment?.status !== 'complete';

        const isAssignedToMeAndIsHierarchical =
            isAssignedToMe &&
            is_hierarchical &&
            (assigment?.assignmentNumber === 1 ||
                assigned
                    .filter(
                        y => y.assignmentNumber < assigment?.assignmentNumber
                    )
                    .every(x => x.groupStatus === 'complete')) &&
            assigment?.groupStatus !== 'complete' &&
            assigment?.status !== 'complete';

        const isCompletedAndReviewedByMe =
            isReviewedByMe && assigned.every(x => x.groupStatus === 'complete');

        const isCompleted =
            (isAssignedToMe || isReviewedByMe) &&
            status === 'completed' &&
            assigned.every(x => x.groupStatus === 'complete') &&
            reviewer.every(x => x.groupStatus === 'complete');

        return (
            isAssignedToMeAndIsNotAHierarchical ||
            isAssignedToMeAndIsHierarchical ||
            isCompletedAndReviewedByMe ||
            isCompleted
        );
    };

const initSchemasCursor = ({ schemas = [], limit, offset }) => {
    return schemas.reduce((acc, schema) => {
        return {
            ...acc,
            [schema]: {
                displayedIds: [],
                limit,
                offset
            }
        };
    }, {});
};

const computeNextSchemasCursor = ({
    limit,
    prevSchemasCursor,
    visitedItemsBySchema
}) => {
    return Object.keys(prevSchemasCursor).reduce((acc, key) => {
        return {
            ...acc,
            [key]: {
                displayedIds: visitedItemsBySchema[key] ?? [],
                limit,
                offset:
                    prevSchemasCursor[key].offset +
                    (visitedItemsBySchema[key]?.length ?? 0)
            }
        };
    }, {});
};

const computeVisitedItemsBySchema = ({ page = [] }) => {
    return page.reduce((ac, activity) => {
        const key = activity.schema;
        if (!ac[key]) {
            ac[key] = [];
        }
        ac[key].push(activity.id);
        return ac;
    }, {});
};

export const tranformData =
    ({ isAssignedToMe, schemas = [], userId }) =>
    data => {
        if (!data) return [];
        userId = Number(userId);
        let itemsWithSchema = schemas.reduce((acc, schema) => {
            const schemaData = data[getActvitiesQueryKey(schema)];
            if (schemaData) {
                return [
                    ...acc,
                    ...schemaData.map(activity => {
                        const { assigned, reviewer } = computeUsersData({
                            groupCompletionRate: activity.group_completion_rate,
                            querySchemas: schemas,
                            schema,
                            userActivities: activity.user_activities,
                            userId
                        });

                        const reference = getPolicyReferences({
                            activityPolicies: activity.activity_policies,
                            user_activities: activity.user_activities
                        });

                        return {
                            ...activity,
                            active: isActivityActive({
                                assigned,
                                isHierarchical: activity.is_hierarchical,
                                reviewer,
                                userId
                            }),
                            assigned,
                            completable: getCompletable({
                                assigned,
                                reviewer,
                                userId
                            }),
                            entities: getEntitiesName(
                                activity.activity_entities
                            ),
                            flagged: Boolean(
                                activity.activity_flags?.length > 0
                            ),
                            policyReference: reference,
                            recurrence: getActivityRecurrences(
                                activity.activity_recurrences
                            ).at(0),
                            reviewer,
                            schema
                        };
                    })
                ];
            }
            return acc;
        }, []);

        if (isAssignedToMe) {
            itemsWithSchema = itemsWithSchema.filter(
                isAssignedToMeFilter({ userId })
            );
        }

        return itemsWithSchema.sort(sortActitivies);
    };

export const useGetActivitiesPaginator = ({
    activityAssignees = [],
    activityDueDateFrom,
    activityDueDateTo,
    activityName,
    activityRecurrence,
    activityReviewers = [],
    activityStatus,
    activityTypes,
    dueDateSort = gqlSortTypes.DESC,
    entityName,
    isAssignedToMe,
    isFlagged,
    limit = 50,
    offset = 0,
    querySchemas = [],
    schemas = [],
    userId
}) => {
    const paginator = useInfiniteQuery({
        enabled: schemas.length > 0,
        getNextPageParam: (
            lastPage = [],
            _allPages = [],
            lastPageParam,
            _allPageParams
        ) => {
            try {
                if (!lastPage || lastPage.length < limit) return undefined;

                const visitedItemsBySchema = computeVisitedItemsBySchema({
                    page: lastPage
                });
                const computedPageParams = {
                    ...lastPageParam,
                    limit,
                    offset: lastPageParam.offset + limit,
                    schemasCursor: computeNextSchemasCursor({
                        limit,
                        prevSchemasCursor: lastPageParam.schemasCursor,
                        visitedItemsBySchema
                    })
                };
                return computedPageParams;
            } catch (error) {
                console.error("Error computing the next page's params", error);
                return null;
            }
        },
        initialPageParam: {
            activityAssignees,
            activityDueDateFrom,
            activityDueDateTo,
            activityName,
            activityRecurrence,
            activityReviewers,
            activityStatus,
            activityTypes,
            dueDateSort,
            entityName,
            isAssignedToMe,
            isFlagged,
            limit,
            offset,
            querySchemas,
            schemas,
            schemasCursor: initSchemasCursor({ limit, offset, schemas }),
            userId
        },
        queryFn: async ({ pageParam }) => {
            try {
                const gqlQuery = buildGqlQuery({
                    query: () => getActivitiesGql(pageParam)
                });

                const client = gqlClient({
                    accessToken: localStorage.accessToken,
                    url: process.env.REACT_APP_GRAPHQL_URL
                });

                const res = await client.request(gqlQuery);
                const transformedData = tranformData({
                    isAssignedToMe,
                    schemas: pageParam.schemas,
                    userId
                })(res);
                const result = transformedData.slice(0, pageParam.limit);

                return result;
            } catch (error) {
                return null;
            }
        },
        queryKey: queryKeys.actvities.getActivitiesPaginator({
            activityAssignees,
            activityDueDateFrom,
            activityDueDateTo,
            activityName,
            activityRecurrence,
            activityReviewers,
            activityStatus,
            activityTypes,
            dueDateSort,
            entityName,
            isAssignedToMe,
            isFlagged,
            limit,
            offset,
            schemas,
            userId
        })
    });

    return paginator;
};

export function useGetActivitiesFiltersValues({ schemas = [] }) {
    return useQuery({
        enabled: schemas.length > 0,
        queryFn: async () => {
            const gqlQuery = buildGqlQuery({
                query: () => getActivitiesFiltersGql({ schemas })
            });
            const client = gqlClient({
                accessToken: localStorage.accessToken,
                url: process.env.REACT_APP_GRAPHQL_URL
            });

            const res = await client.request(gqlQuery);
            return res;
        },
        queryKey: queryKeys.actvities.getActivitiesFilters(schemas),
        select: activitiesFilterDataTransformer(schemas)
    });
}

export function optimisticUpdateActivity({
    queryParams: {
        activityAssignees = [],
        activityDueDateFrom,
        activityDueDateTo,
        activityName,
        activityRecurrence,
        activityReviewers = [],
        activityStatus,
        activityTypes,
        dueDateSort,
        entityName,
        isAssignedToMe,
        isFlagged,
        limit = 50,
        offset = 0,
        schemas = [],
        userId
    },
    activityId,
    updateValues
}) {
    queryClient.setQueryData(
        queryKeys.actvities.getActivitiesPaginator({
            activityAssignees,
            activityDueDateFrom,
            activityDueDateTo,
            activityName,
            activityRecurrence,
            activityReviewers,
            activityStatus,
            activityTypes,
            dueDateSort,
            entityName,
            isAssignedToMe,
            isFlagged,
            limit,
            offset,
            schemas,
            userId
        }),
        oldData => {
            const newPages =
                oldData?.pages.map(page =>
                    page.map(activity => {
                        if (activity.id === activityId) {
                            return {
                                ...activity,
                                ...updateValues
                            };
                        }

                        return activity;
                    })
                ) ?? [];

            return {
                pageParams: oldData.pageParams,
                pages: newPages
            };
        }
    );
}

export function useCloseActivity() {
    return useMutation({
        mutationFn: async ({
            activityId,
            completable,
            groupId,
            schema,
            userId
        }) => {
            try {
                const gqlQuery = buildGqlQuery({
                    query: () =>
                        closeActivityMutationGql({
                            activityId,
                            completable,
                            groupId,
                            schema,
                            userId
                        })
                });

                const client = gqlClient({
                    accessToken: localStorage.accessToken,
                    url: process.env.REACT_APP_GRAPHQL_URL
                });

                const res = await client.request(gqlQuery);
                return res;
            } catch (error) {
                console.error(error);
                return null;
            }
        }
    });
}

export const getAllActivitites = async ({
    activityAssignees = [],
    activityDueDateFrom,
    activityDueDateTo,
    activityName,
    activityRecurrence,
    activityReviewers = [],
    activityStatus,
    activityTypes,
    dueDateSort,
    entityName,
    isAssignedToMe,
    isFlagged,
    schemas = [],
    userId
}) => {
    try {
        const gqlQuery = buildGqlQuery({
            query: () =>
                getAllActivitiesGql({
                    activityAssignees,
                    activityDueDateFrom,
                    activityDueDateTo,
                    activityName,
                    activityRecurrence,
                    activityReviewers,
                    activityStatus,
                    activityTypes,
                    dueDateSort,
                    entityName,
                    isAssignedToMe,
                    isFlagged,
                    schemas,
                    userId
                })
        });

        const client = gqlClient({
            accessToken: localStorage.accessToken,
            url: process.env.REACT_APP_GRAPHQL_URL
        });

        const res = await client.request(gqlQuery);
        const transformedData = tranformData({
            isAssignedToMe,
            schemas,
            userId
        })(res);

        return transformedData;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const useGetAllActivitiesForExport = () => {
    return useMutation({
        mutationFn: getAllActivitites
    });
};

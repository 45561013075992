import ActionMenu from 'components/ActionMenu2';
import CloseCaseAndAddLogEntryMenuListItem from 'components/CloseCaseAndAddLogEntryMenuListItem';
import CloseCaseAndCreateNewActivityMenuListItem from 'components/CloseCaseAndCreateNewActivityMenuListItem';
import CloseCaseMenuListItem from 'components/CloseCaseMenuListItem';

const CloseCaseMenu = ({ caseId, caseRecord, children, onBeforeClose, onClick }) => {
    const activity = caseRecord.activityCase.activity;
    let activityId;
    let activityTitle;

    if (activity.id > 0) {
        activityId = activity.id
        activityTitle = activity.title
    }
    
    return (
        <ActionMenu
            renderReferenceElement={({ handleClick }) => children(handleClick)}
        >
            {onPopperClose => (
                <>
                    <CloseCaseMenuListItem
                        caseId={caseId}
                        onBeforeClose={onBeforeClose}
                        onClick={onPopperClose}
                    />

                    <CloseCaseAndCreateNewActivityMenuListItem
                        activityId={activityId || ''}
                        activityName={activityTitle || ''}
                        caseId={caseId}
                        caseName={caseRecord.type.name}
                        onBeforeClose={onBeforeClose}
                        onClick={onPopperClose}
                    />

                    <CloseCaseAndAddLogEntryMenuListItem
                        caseId={caseId}
                        caseRecord={caseRecord}
                        onBeforeClose={onBeforeClose}
                        onClick={onPopperClose}
                        onSave={onClick}
                    />
                </>
            )}
        </ActionMenu>
    )
};

export default CloseCaseMenu;

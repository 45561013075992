export function dateParts(value) {
    const [date, time] = new Date(value).toISOString().split('T');
    return { date, time };
}

export function daysBetween(minDate = new Date(), maxDate = new Date()) {
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.ceil((new Date(maxDate) - new Date(minDate)) / oneDay);
}

export function addDaysToDate(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

import { useEffect, useState } from 'react'; 

import state from 'app/state';

import getFile from 'utilities/getFile';

import image from './image.svg';

import { altText } from './constants';

import styles from './styles.module.scss';
import useConfig from 'hooks/useConfig';

const { logo } = state;

const { logoAtom } = logo.atoms;

const Logo = ({ className = '', onClick = () => {} }) => {
    const { API_KEY, GET_FILE_URL } = useConfig();
    const schema = localStorage.getItem('schema');

    const [imageObject, setImageObject] = useState(logoAtom);
    const [navigationImage, setImage] = useState(undefined)

    useEffect(() => {
        async function getLogo(){
            const logoToUse = window.location.href.includes('regtechsolutions') ? 'salus' : schema;
            const urlString = await getFile(logoToUse, "logo.png", API_KEY, GET_FILE_URL);
            if (urlString && urlString.length > 0){
                const newImageObject = Object.assign(imageObject, urlString);
                setImageObject(newImageObject);
            }
            return urlString;
        }
        async function setLogo(){
            const logo = await getLogo();
            if(logo){
                setImage(logo);
            }else{
                setImage(image)
            }
        }
        setLogo();
        //eslint-disable-next-line
    }, [imageObject, schema, setImage, setImageObject]);

    return (
        <>
        {navigationImage && navigationImage !== undefined ? (
            <img alt={altText} className={`${className} ${styles.image}`} onClick={onClick} src={navigationImage} />
        ):(
            <></>
        )}
        </>
    );
}

export default Logo;

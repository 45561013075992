import { useEffect, useState } from 'react';

import CaseTableHeader from 'components/CaseTableHeader';
import CaseTableRow from 'components/CaseTableRow';
import Table from 'components/Table';
import TableFooter from 'components/TableFooter';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PageTitle';
import CancelDeleteButtons from 'components/CancelDeleteButtons';
import ImportantText from 'components/ImportantText';

import useTranslation from './hooks/useTranslation';
import useData from './hooks/useData';

import styles from './styles.module.scss';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const CaseTable = ({
    className = '',
    closedCases = [],
    dashboard = false,
    onDownloadItem,
    overdueCases = [],
    pendingCases = []
}) => {
    const { useTenants, useUserId, useSchema } = useGlobalStateHooks();
    const [schema] = useSchema();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [caseToDelete, setCaseToDelete] = useState({
        id: null,
        name: null
    });

    const [deletedCase, deleteCase, reset] = useData(schema);

    const {
        ASSIGNED_TO,
        CASE_NAME,
        CLOSED,
        COMPANY,
        DATE_CLOSED,
        DAYS_OUTSTANDING,
        DELETE_TITLE,
        DELETE_TEXT,
        DUE_DATE,
        ENTITIES,
        OVERDUE,
        PENDING,
        RISK_LABEL,
        USERS_INVOLVED,
        ACTIONS
    } = useTranslation();

    const [tenants] = useTenants();
    const [userId] = useUserId();

    const content = {

        overdue: {
            header: dashboard
                ? [OVERDUE, CASE_NAME, DUE_DATE, ASSIGNED_TO]
                : tenants && tenants.length > 1
                ? [
                      OVERDUE,
                      CASE_NAME,
                      DUE_DATE,
                      DAYS_OUTSTANDING,
                      ASSIGNED_TO,
                      DATE_CLOSED,
                      RISK_LABEL,
                      USERS_INVOLVED,
                      COMPANY,
                      ENTITIES,
                      ACTIONS
                  ]
                : [
                      OVERDUE,
                      CASE_NAME,
                      DUE_DATE,
                      DAYS_OUTSTANDING,
                      ASSIGNED_TO,
                      DATE_CLOSED,
                      RISK_LABEL,
                      USERS_INVOLVED,
                      ENTITIES,
                      ACTIONS
                  ],
            items: overdueCases,
            style: 'overdue'
        },
        pending: {
            header: dashboard
                ? [PENDING, CASE_NAME, DUE_DATE, ASSIGNED_TO]
                : tenants && tenants.length > 1
                ? [
                      PENDING,
                      CASE_NAME,
                      DUE_DATE,
                      DAYS_OUTSTANDING,
                      ASSIGNED_TO,
                      DATE_CLOSED,
                      RISK_LABEL,
                      USERS_INVOLVED,
                      COMPANY,
                      ENTITIES,
                      ACTIONS
                  ]
                : [
                      PENDING,
                      CASE_NAME,
                      DUE_DATE,
                      DAYS_OUTSTANDING,
                      ASSIGNED_TO,
                      DATE_CLOSED,
                      RISK_LABEL,
                      USERS_INVOLVED,
                      ENTITIES,
                      ACTIONS
                  ],
            items: pendingCases,
            style: 'pending'
        },
				// eslint-disable-next-line sort-keys-fix/sort-keys-fix
				closed: {
					header: dashboard
							? [CLOSED, CASE_NAME, DUE_DATE, ASSIGNED_TO]
							: tenants && tenants.length > 1
							? [
										CLOSED,
										CASE_NAME,
										DUE_DATE,
										DAYS_OUTSTANDING,
										ASSIGNED_TO,
										DATE_CLOSED,
										RISK_LABEL,
										USERS_INVOLVED,
										COMPANY,
										ENTITIES,
                                        ACTIONS
								]
							: [
										CLOSED,
										CASE_NAME,
										DUE_DATE,
										DAYS_OUTSTANDING,
										ASSIGNED_TO,
										DATE_CLOSED,
										RISK_LABEL,
										USERS_INVOLVED,
										ENTITIES,
                                        ACTIONS
								],
					items: closedCases,
					style: 'closed'
			},
    };

    const onDeleteCase = () => {
        const formObject = {caseId: caseToDelete.id, userId};
        deleteCase(formObject);
        reset();
    };

    useEffect(() => {
        if(deletedCase){
            window.location.reload();
        }
    }, [deletedCase]);

    return (
        <>
            <Table className={`${className} ${styles.caseTable}`}>
                {Object.entries(content).map(([key, { header, items, style }]) => {
                    if(key === 'overdue' && items.length === 0){
                        return null;
                    }
                    
                    return (
                        <div key={`${style}-cases-table`}>
                            <CaseTableHeader
                                className={styles.tableHeader}
                                dashboard={dashboard}
                                items={header}
                                key={`${style}-cases`}
                                styleType={style}
                                tenantCount={tenants.length}
                            />

                            {items.length === 0 && (
                                <p className={styles.noItems}>No items to show</p>
                            )}

                            {items.map((item, index) => {
                                const {
                                    activityId,
                                    activityName,
                                    assignedTo,
                                    caseName,
                                    caseSchema,
                                    caseType,
                                    closedDate,
                                    createdDate,
                                    creationReason,
                                    dueDate,
                                    entityTags,
                                    id,
                                    originated,
                                    proposedSolution,
                                    resolution,
                                    riskLabel,
                                    usersInvolved
                                } = item;

                                return (
                                    <CaseTableRow
                                        activityId={activityId}
                                        activityName={activityName}
                                        assignedTo={assignedTo}
                                        caseName={caseName}
                                        caseSchema={caseSchema}
                                        caseType={caseType}
                                        closedDate={closedDate}
                                        createdDate={createdDate}
                                        creationReason={creationReason}
                                        dashboard={dashboard}
                                        dueDate={dueDate}
                                        entityTags={entityTags}
                                        id={id}
                                        item={item}
                                        key={`${caseType} ${index} ${caseSchema}`}
                                        onDownloadItem={onDownloadItem}
                                        originated={originated}
                                        proposedSolution={proposedSolution}
                                        resolution={resolution}
                                        riskLabel={riskLabel}
                                        setCaseToDelete={setCaseToDelete}
                                        setIsModalOpen={setIsModalOpen}
                                        styleType={style}
                                        tenantCount={tenants.length}
                                        usersInvolved={usersInvolved}
                                    />
                                );
                            })}
                        </div>
                    );
                })}

                <TableFooter className={styles.tableFooter}></TableFooter>
            </Table>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <PanelBody>
                    <PanelTitle className={styles.panelTitle} text={DELETE_TITLE} />

                    <ImportantText text={`${DELETE_TEXT} ${caseToDelete.name}?`} />

                    <CancelDeleteButtons
                        className={styles.cancelSaveButtons}
                        onCancel={() => setIsModalOpen(false)}
                        onDelete={onDeleteCase}
                    />
                </PanelBody>
            </Modal>
        </>
    );
};

export default CaseTable;

import { useQuery } from '@apollo/client';

import generateQueryFromDict from 'utilities/generateQueryFromDict';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const query = (tenantSchema, userGQL) => `
    ${tenantSchema}_activity(
        where: {
            deleted: {_eq: false}
        }
    ) {
        id 
        start_date 
        due_date 
        name 
        group_completion_rate
        closed,
        risk_label,
        closed_date
        is_hierarchical
        activity_type {
            name
        } 
        activity_policies {
            document {
                description
                id
                name
                risk_label
            }
            section {
                description
                id
                name
                riskLevel
            }
        }
        user_activities(where: {status: {_neq: "removed"}}) {
            status
            assignment_type
            tenant_user {
                id
                ${userGQL}
            }
            group {
                name
                id
            }
        }
        activity_entities {
            entity {
                name
            }
        }
        activity_flags(where: {id: {_is_null: false}}) {
            user_id
        }
        activity_recurrences(where: {activity_recurrence: {is_active: {_eq: true}}}) {
          activity_recurrence {
            recurrence_type
          }
        }
    }
`

const useData = () => {
    const { useQuerySchemas, useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [querySchemas] = useQuerySchemas()
    const [tenants] = useTenants();
    const [userSetSchema] = useUserSetSchema();
    const usedSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
    var userGQL = '';
    if(querySchemas.length > 0){
        for(const s of querySchemas){
            userGQL += `users_${s.value} {
                first_name 
                last_name 
                profile_photo
            }`
        }
    }else if(usedSchema){
        userGQL += `users_${usedSchema} {
            first_name
            last_name
            profile_photo
        }`
    }
    const gqlQuery = generateQueryFromDict({query, schema:usedSchema, tenants, userGQL})
    return useQuery(gqlQuery, { fetchPolicy: 'no-cache' });
};

export default useData;

import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql, useApolloClient } from '@apollo/client';

import state from 'app/state';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateRecurrenceEndDateModal } = state;

const { updateRecurrenceEndDateModalAtom } = updateRecurrenceEndDateModal.atoms;

const MUTATE_UPDATE_ACTIVITIES = ({ tenantSchema }) => gql`
mutation updateActivity($updateActivities: [${tenantSchema}_activity_updates!]!) {
    update_${tenantSchema}_activity_many(updates: $updateActivities){ returning { id } }
  }
`;

const useData = providedSchema => {
    const client = useApolloClient();
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const updateActivities = useRecoilCallback(
        ({ set }) =>
            ({ onSuccess, recurrences = [] }) => {
                const runMutation = async () => {
                    if (recurrences.length === 0) {
                        console.error('No recurrences provided');
                        return;
                    }

                    await client.mutate({
                        mutation: MUTATE_UPDATE_ACTIVITIES({
                            tenantSchema: providedSchema ?? schema
                        }),
                        variables: {
                            updateActivities: recurrences.map(
                                ({ futureDueDate, id, futureStartDate }) => ({
                                    _set: isNaN(futureStartDate)
                                        ? {
                                              deleted: true
                                          }
                                        : {
                                              due_date: futureDueDate,
                                              start_date: futureStartDate
                                          },
                                    where: {
                                        id: { _eq: id }
                                    }
                                })
                            )
                        }
                    });

                    set(updateRecurrenceEndDateModalAtom, true);
                    if (onSuccess) {
                        onSuccess();
                    }
                };

                runMutation();
            }
    );

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateRecurrenceEndDateModalAtom, false);
    });

    return [
        useRecoilValue(updateRecurrenceEndDateModalAtom),
        updateActivities,
        reset
    ];
};

export default useData;

import { useRecoilCallback } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateEndDate } = state;

const { updateEndDateAtom } = updateEndDate.atoms;

const MUTATE = tenantSchema => gql`
mutation mutateActivityInstructions($text: timestamp!, $activityId: Int!) {
    update_${tenantSchema}_activity(where: {id: {_eq: $activityId}}, _set: {due_date: $text}) {
      returning {
        due_date
      }
    }
  }
`;

export const useUpdateActivityDueDate = providedSchema => {
    const mutation = useGraphQL(MUTATE, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveEndDate = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const res = await mutation(variables);

            const newEndDate =
                res?.data[`update_${usedSchema}_activity`].returning[0]
                    ?.due_date;

            set(updateEndDateAtom, newEndDate);
        };

        runMutation();
    });

    return saveEndDate;
};

import isFunction from 'utilities/isFunction';
import styles from './styles.module.scss';
import { useState } from 'react';

const CheckboxDynamicReturn = ({
    className = '',
    isChecked,
    isDisabled = false,
    label,
    onChange
}) => {

    const [selected, setSelected] = useState(isChecked);

    const handleChange = () => {
        if (isFunction(onChange)) {
            onChange(label, !selected);
            setSelected(!selected);
        }
    };

    return (
        <label className={`${className} ${styles.checkbox}`}>
            <input
                className={styles.input}
                defaultChecked={selected}
                disabled={isDisabled}
                onChange={handleChange}
                type="checkbox"
            />

            <span className={styles.checkmark} />
        </label>
    );
};

export default CheckboxDynamicReturn;

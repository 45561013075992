import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CancelSaveButtons from 'components/CancelSaveButtons';
import DatePicker from 'components/DatePicker';
import Dropdown from 'components/Dropdown';
import DropdownWithTags from 'components/DropdownWithTags';
import Editor from 'components/Editor';
import FormField from 'components/FormField';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';
import RiskLevelRadioButtons from 'components/RiskLevelRadioButtons';
import TextInput from 'components/TextInput';

import useConfig from 'hooks/useConfig';
import useForm from 'hooks/useForm';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';
import useDataQuery from './hooks/useDataQuery';
import useTranslation from './hooks/useTranslation';
import usePolicies from './hooks/usePolicies';
import useTypes from './hooks/useTypes';

import styles from './styles.module.scss';

import normalizePolicyData from './dataPolicies';
import normalizeTypeData from './dataTypes';
import titleCase from 'utilities/titleCase';

const CreateCaseModal = ({ onClose, linkSchema }) => {
    const { CASE_ROUTE, INTERNAL_SERVER_ERROR } = useConfig();
    const {
        ASSIGNED_TO,
        CASE_CREATION_REASON,
        CASE_NAME,
        CASE_TYPE,
        CREATE_CASE,
        DUE_DATE,
        ENTER_CASE_NAME,
        POLICY_REFERENCE,
        RISK_LEVEL_CASE
    } = useTranslation();

    const navigate = useNavigate();

    const { useConsultancyUsers, useSchema, useTenants, useUserId, useUserSetSchema } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userId] = useUserId();
    const [userSetSchema, setUserSetSchema] = useUserSetSchema();
    const [consultancyUsers] = useConsultancyUsers();

    const [policies, setPolicies] = usePolicies();
    const [types, setTypes] = useTypes();

    const [_errorMessage, setErrorMessage] = useState();
    const [consultancyUsersForUse, setConsultancyUsersForUse] = useState([]);

    const [createdCase, createCase, reset] = useData();

    const [editorState, setEditorState] = useState(`<p></p>`);

    const handleClose = useCallback(
        event => {
            if (isFunction(onClose)) {
                return onClose(event);
            }
        },
        [onClose]
    );

    const { loading, error, data } = useDataQuery();

    const form = useForm({
        assignedTo: '',
        caseName: '',
        caseType: '',
        creationReason: '',
        dueDate: '',
        policyId: '',
        riskLevel: ''
    });

    const { handleError, handleForm, handleSubmit, handleValid } = form;

    const handleSave = useCallback(() => {
        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }
        const formObject = {};

        output.forEach((value, key) => (formObject[key] = value));

        formObject['createdBy'] = userId;
        var newDate;
        if (formObject['dueDate'] === ''){
            const today = new Date();
            newDate = new Date(today.setDate(today.getDate() + 180));
        }else{
            newDate = new Date(formObject['dueDate']);
        }
        formObject['dueDate'] = newDate;
        formObject['originated'] = 'adhoc';
        formObject['sectionId'] = formObject['policyId'].includes(".") ? formObject['policyId'].split('.')[1] !== '0'
                ? Number(formObject['policyId'].split('.')[1])
                : null
                : null;
        formObject['policyId'] = formObject['policyId'].includes(".") ? formObject['policyId'].split('.')[0] : formObject['policyId'];

        formObject['policyId'] = formObject['policyId'] !== '' ? Number(formObject['policyId']) : null;

        formObject['formId'] = formObject['caseType'] !== '' ? types.filter((type) => type.value === Number(formObject['caseType']))[0].formId : null;

        createCase(formObject);
        //eslint-disable-next-line
    }, [createCase, handleForm, setErrorMessage, userId]);

    const setTenant = selectedTenant => {
        setUserSetSchema(selectedTenant);
    }

    useEffect(() => {
        if (data) {
            const tenantSchema = linkSchema ? linkSchema : tenants && tenants.length === 1 ? tenants[0] : userSetSchema ? userSetSchema : schema;
            const normalizedPolicyData = normalizePolicyData({ data, schema:tenantSchema, tenants });
            const normalizedTypeData = normalizeTypeData({ data, schema:tenantSchema, tenants });

            const filteredPolicy = userSetSchema ? normalizedPolicyData.filter(function(policy){ return policy.schema === tenantSchema;}) : normalizedPolicyData;
            const filteredTypes = userSetSchema ? normalizedTypeData.filter(function(type){ return type.schema === tenantSchema;}) : normalizedTypeData;

            setPolicies(filteredPolicy);
            setTypes(filteredTypes);
            setConsultancyUsersForUse(consultancyUsers.filter(item => item.tenants.includes(tenantSchema)))
        }
        //eslint-disable-next-line
    }, [consultancyUsers, data, schema, setConsultancyUsersForUse, setPolicies, setTypes, tenants, userSetSchema]);

    useEffect(() => {
        if (!createdCase) {
            return;
        }

        if (isFunction(onClose)) {
            onClose(createdCase);

            navigate(CASE_ROUTE.replace(':caseId', createdCase).replace(':caseSchema', userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema));

            reset();
        }
    }, [CASE_ROUTE, createdCase, navigate, onClose, reset, userSetSchema, schema, tenants]);

    if (error) {
        navigate(INTERNAL_SERVER_ERROR);
        return;
    }

    return (
        <Modal isOpen={true} onClose={handleClose} overlayClose={false}>
            <PanelBody loading={loading}>
                <PanelTitle className={styles.panelTitle} text={CREATE_CASE} />

                {!linkSchema && tenants.length > 1 &&
                    <Dropdown
                        className={styles.dropdownWide}
                        isRequired={true}
                        items={tenants.map(tenant => ({label: titleCase(tenant), value: tenant}))}
                        label="Tenant"
                        onChange={setTenant}
                        onError={handleError('tenant')}
                        onSubmit={handleSubmit('tenant')}
                        onValid={handleValid('tenant')}
                    />
                }

                <Dropdown
                    isRequired={true}
                    items={types ? types : []}
                    label={CASE_TYPE}
                    onError={handleError('caseType')}
                    onSubmit={handleSubmit('caseType')}
                    onValid={handleValid('caseType')}
                />

                <TextInput
                    isRequired={true}
                    label={CASE_NAME}
                    onChange={handleValid('caseName')}
                    onError={handleError('caseName')}
                    onSubmit={handleSubmit('caseName')}
                    placeholder={ENTER_CASE_NAME}
                />

                <Dropdown
                    items={policies}
                    label={POLICY_REFERENCE}
                    onError={handleError('policyId')}
                    onSubmit={handleSubmit('policyId')}
                    onValid={handleValid('policyId')}
                />

                <FormField label={CASE_CREATION_REASON} >
                    <Editor
                        html={editorState}
                        onChange={setEditorState}
                        onValid={handleValid('creationReason')}
                        placeholder={CASE_CREATION_REASON}
                    />
                </FormField>

                <RiskLevelRadioButtons
                    label={RISK_LEVEL_CASE}
                    onChange={handleValid('riskLevel')}
                />

                <DropdownWithTags
                    items={consultancyUsersForUse}
                    label={ASSIGNED_TO}
                    onChange={handleValid('assignedTo')}
                    tags={[]}
                />

                <DatePicker
                    label={DUE_DATE}
                    onChange={handleValid('dueDate')}
                />

                <CancelSaveButtons
                    className={styles.cancelSaveButtons}
                    onCancel={handleClose}
                    onSave={handleSave}
                />
            </PanelBody>
        </Modal>
    );
};

export default CreateCaseModal;

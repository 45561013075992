import { useCallback, useEffect } from 'react'; 
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import httpAuth from 'utilities/httpAuth';
import httpAuthRefresh from 'utilities/httpAuthRefresh';

// import useSchema from './hooks/useSchema';
import useData from './hooks/useData';
import lexigraphicalSort from 'utilities/lexigraphicalSort';
import { safeSetLocalStorage } from 'utilities/localStorage';

const Authenticated = ({ children }) => {
    const { AUTH_WEBHOOK, HOME, LOGIN, REFRESH_WEBHOOK } = useConfig();

    const { useConsultancyUsers, useEmail, useIsAllFilter, useIsFlaggedFilter, usePermissions, useSchema, useSchemaDescription, useTenants, useUserId, useUserSetSchema} = useGlobalStateHooks({
        useActivities: 'filters.activities',
        useActivityTypeFilter: 'filters.activityType',
        useConsultancyUsers: 'authenticated.consultancyUsers',
        useEmail: 'authenticated.email',
        useEndDateFilter: 'filters.endDate',
        useEntityFilter: 'filters.entity',
        useIsAllFilter: 'filters.isAll',
        useIsFlaggedFilter: 'filters.isFlagged',
        usePermissions: 'authenticated.permissions',
        useQuerySchemas: 'authenticated.querySchemas',
        useReviewFilter: 'filters.review',
        useRiskLabelFilter: 'filters.riskLabel',
        useSchema: 'authenticated.schema',
        useSchemaDescription: 'authenticated.schemaDescription',
        useStartDateFilter: 'filters.startDate',
        useTenants: 'authenticated.tenants',
        useUserFilter: 'filters.user',
        useUserId: 'authenticated.userId',
        useUserSetSchema: 'authenticated.userSetSchema',
    });

    const [____, setConsultancyUsers] = useConsultancyUsers();
    const [_, setEmail] = useEmail();
    const [permissions, setPermissions] = usePermissions();
    const [schema, setSchema] = useSchema();
    const [___, setSchemaDescription] = useSchemaDescription();
    const [__, setIsAll] = useIsAllFilter();
    const [_____, setIsFlagged] = useIsFlaggedFilter();
    // const [tenantUsers, setTenantUsers] = useTenantUsers();
    const [tenants, setTenants] = useTenants();
    const [userId, setUserId] = useUserId();
    const [_userSetSchema, setUserSetSchema] = useUserSetSchema();

    const navigate = useNavigate();

    const redirectToLogin = useCallback(() => {
        localStorage.clear();
                        
        navigate(LOGIN);
    }, [LOGIN, navigate]);

    useEffect(() => {
        const fetchAuth = async () => {
            const {
                Expires: expires,
                'X-Hasura-Role': roleHasura,
                'X-Hasura-User-ID': loginUserId
            } = await httpAuth(AUTH_WEBHOOK);

            if (expires) {
                const expiresDate = new Date(expires);

                if (expiresDate >= new Date()) {
                    const tenantSchema = roleHasura.split('_')[0];

                    localStorage.setItem('tokenExpires', expiresDate);
                    localStorage.setItem('userID', loginUserId);
                    localStorage.setItem('schema', tenantSchema);

                    setUserId(loginUserId);
                    setSchema(tenantSchema);
                    if(!tenants || (!Array.isArray(tenants))){
                        if(localStorage.getItem("tenants")){
                            setTenants(localStorage.getItem("tenants").split(","))
                        }else{
                            setTenants([tenantSchema])
                        }
                    }
                } else {
                    const tenantSchema = roleHasura.split('_')[0];
                    
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('tokenExpires');
                    localStorage.removeItem('userID');

                    setUserId('');
                    setSchema('');
                    setUserSetSchema(tenantSchema);
                    setTenants([]);

                    const {
                        access_token: accessToken,
                        refresh_token: refreshToken
                    } = await httpAuthRefresh(REFRESH_WEBHOOK);

                    if (accessToken) {
                        localStorage.setItem('accessToken', accessToken);
                    }
                    if (refreshToken) {
                        localStorage.setItem('refreshToken', refreshToken);
                    }

                    fetchAuth();
                }
            } else {
                const {
                    access_token: accessToken,
                    refresh_token: refreshToken
                } = await httpAuthRefresh(REFRESH_WEBHOOK);

                if (accessToken && refreshToken) {
                    localStorage.setItem('accessToken', accessToken);
                    localStorage.setItem('refreshToken', refreshToken);

                    window.location.reload();
                }else{
                    redirectToLogin();
                }
            }
        };

        fetchAuth();
    }, [
        AUTH_WEBHOOK,
        HOME,
        LOGIN,
        REFRESH_WEBHOOK,
        navigate,
        redirectToLogin,
        setConsultancyUsers,
        setIsAll,
        setIsFlagged,
        setSchema,
        setUserId,
        setTenants,
        setUserSetSchema,
        tenants
    ]);

    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    const { loading, error, data } = useData(userId);

    if (loading) {
    }

    if (error) {
    }

    useEffect(() => {
        if (data) {
            const dataTenants = data[`tenant`];
            const consultancy = data[`consultancy`][0]?.description;
            const consultancyId = data[`consultancy`][0]?.id;
            const tenantConsultantIds = dataTenants.reduce((accumulator, tenant) => {
                if(tenant.consultancy_id === consultancyId){
                    accumulator.push(tenant.tenant_id);
                }
                return accumulator;
            }, []);
            const consultancyUsers = data[`consultancy_users`].reduce(
                (accumulator, user) => {
                    const userId = user?.user_id;
                    const userTenantId = user?.tenant_id;
                    const userConsultancyId = user?.consultancy_id;
                    if(userTenantId===consultancyId || tenantConsultantIds.includes(userConsultancyId)){
                        accumulator.push(userId);
                    }
                    return accumulator
                },[]
            );
            const tenantUsers = data[`tenant_user`].reduce(
                (accumulator, user) => {
                    const userId = user?.id;
                    const userConsultancyId = user?.consultancy_id;
                    if(userConsultancyId===consultancyId || userConsultancyId){
                        accumulator.push(userId);
                    }
                    return accumulator
                },[]
            );
            const emails = data[`${localStorage.schema}_user`]
            const groups = data[`${localStorage.schema}_group`] || []
            const groupUsers = data[`${localStorage.schema}_group_user`] || []

            const consolidatedUserIds = [...new Set([...consultancyUsers, ...tenantUsers])];
            var consolidatedDataUsers = {};
            let consolidatedUsers = [];

            for(const userKey of Object.keys(data)){
                if(userKey.includes('user') && !userKey.includes('group') && userKey !== 'tenant_user' && userKey !== 'consultancy_users'){
                    const dataUsers = data[userKey].reduce((accumulator, user) => {
                        accumulator[user['tenant_user_id']] = {
                            'avatar': user['profile_photo'],
                            'email': user['tenant_user']['email'],
                            'firstName': user['first_name'],
                            'id': user['tenant_user_id'],
                            'label': `${user['first_name']} ${user['last_name']}`,
                            'lastName': user['last_name'],
                            'name': `${user['first_name']} ${user['last_name']}`,
                            'profilePhoto': user['profile_photo'],
                            'schema': user['__typename'].replace('_user', ''),
                            'searchString': `${user['first_name']} ${user['last_name']}`,
                            'tenants': [...new Set(data?.consultancy_users.reduce((accumulator, tenant) => {
                                if(user['tenant_user_id'] === tenant?.user_id){
                                    accumulator.push(tenant?.consultancy_users_consultancy?.name);
                                    accumulator.push(tenant?.consultancy_users_tenant?.name);
                                }
                                return accumulator;
                            },[user['__typename'].replace('_user', '')]) || [user['__typename'].replace('_user', '')])],
                            'type': "user",
                            'value': user['tenant_user_id'],
                            ...user
                        };
                        return accumulator
                    }, {});
                    consolidatedDataUsers = {...consolidatedDataUsers, ...dataUsers};
                }
            }
            
            for(const consolidatedUserId of consolidatedUserIds){
                if(Object.keys(consolidatedDataUsers).includes(String(consolidatedUserId))){
                    consolidatedUsers.push(consolidatedDataUsers[consolidatedUserId])
                }
            }

            consolidatedUsers = lexigraphicalSort(consolidatedUsers, ['label']);

            setConsultancyUsers(consolidatedUsers);
            setSchemaDescription(consultancy);

            const authenticatedUser = emails ? emails.filter(obj => obj.tenant_user_id===Number(userId))[0] : {'first_name':'', 'last_name':'', 'tenant_user':{'email':''}};
            localStorage.setItem(
                'firstName',
                authenticatedUser?.first_name || ''
            );

            localStorage.setItem(
                'lastName',
                authenticatedUser?.last_name || ''
            );
            setEmail(authenticatedUser?.tenant_user?.email || '');

            localStorage.setItem(
                'email',
                authenticatedUser?.tenant_user?.email || ''
            );

            safeSetLocalStorage(
                'openActivitiesAndCasesInNewTab',
                Boolean(authenticatedUser?.new_tab)
            );

            const users = emails.reduce((accumulator, user) => {
                const {
                    first_name: firstName,
                    last_name: lastName,
                    tenant_user_id: id,
                    tenant_user: tenantUser
                } = user;
                const { email } = tenantUser;
                const searchString = `${firstName} ${lastName}`;
                accumulator.push({ email, firstName, id, lastName, searchString })
                return accumulator
            }, []);

            localStorage.setItem(
                'users',
                JSON.stringify(consolidatedUsers)
            );

            const organizedGroups = groups.reduce((accumulator, group) => {
                accumulator[group?.id] = {name: group?.name, users: []};
                return accumulator;
            }, {});
            for(const u of groupUsers){
                organizedGroups[u?.group_id]?.users.push(users.filter(us => us?.id === u?.tenant_user_id)[0])
            }

            const normalizedGroups = Object.entries(organizedGroups).reduce(
                (accumulator, [key, values]) => {
                    const searchString = `${values?.name} Group`;
                    const modifiedGroup = {
                        email: 'group',
                        firstName: values?.name,
                        id: key,
                        lastName: 'Group',
                        searchString,
                        users: values?.users
                    }

                    accumulator.push(modifiedGroup);

                    return accumulator;
                }, []);

            localStorage.setItem(
                'groups',
                JSON.stringify(normalizedGroups.filter(x => x !== null))
            );

            if(!permissions){
                setIsAll(false);
                setIsFlagged(true);
                const userPermissions = authenticatedUser?.tenant_user?.user_roles.reduce((accumulator, role) => {
                    const userRoles = role?.role?.role_permissions.reduce((accumulator, permissions) => {
                        const rolePermissions = permissions?.internal_permission?.permission;
                        accumulator.push(rolePermissions);
                        return accumulator
                    }, [])
                    accumulator.push(...userRoles);
                    return accumulator;
                }, []) || [];
                setPermissions(userPermissions);
            }
        }
    }, [data, permissions, setConsultancyUsers, setEmail, setIsAll, setIsFlagged, setPermissions, setSchemaDescription, tenants, userId]);

    if (!accessToken && !refreshToken) {
        return redirectToLogin();
    }

    if (!schema) {
        return null;
    }

    return children;
};

export default Authenticated;

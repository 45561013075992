import {
    optimisticUpdateActivity,
    useCloseActivity,
    useGetActivitiesPaginator
} from 'queries/activities.query';
import {
    useFlagActivity,
    useUnflagActivity
} from 'queries/activity-flags.query';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

export function useActivitiesTable(queryParams) {
    const { userId } = queryParams;
    const { mutateAsync: flagActivity } = useFlagActivity();
    const { mutateAsync: unflagActivity } = useUnflagActivity();
    const { mutateAsync: closeActivity } = useCloseActivity();

    const { useQuerySchemas } = useGlobalStateHooks();
    const [querySchemas] = useQuerySchemas();

    const result = useGetActivitiesPaginator({ ...queryParams, querySchemas });

    const activities = (
        result?.data?.pages?.map(page => page).flat() || []
    ).filter(Boolean);

    const onToggleFlag = async ({ activity, flagged }) => {
        const { id, schema } = activity;
        optimisticUpdateActivity({
            activityId: id,
            queryParams,
            updateValues: { flagged }
        });
        let action = flagged ? flagActivity : unflagActivity;
        await action({ activityId: id, schema, userId });
    };

    const onCompleteActivity = async activity => {
        const { completable, id, schema } = activity;
        optimisticUpdateActivity({
            activityId: id,
            queryParams,
            updateValues: {
                close_date: new Date(),
                closed: true,
                status: 'completed'
            }
        });

        await closeActivity({
            activityId: id,
            completable,
            schema,
            userId
        });
    };

    return {
        ...result,
        activities,
        onCompleteActivity,
        onToggleFlag
    };
}

import CheckboxDynamicReturn from 'components/CheckboxDynamicReturn';

const WithCheckboxDynamicReturn = props => {
    const { children, className = '', isChecked, isDisabled, label, onChange } = props;

    return (
        <>
            <CheckboxDynamicReturn
                className={className}
                isChecked={isChecked}
                isDisabled={isDisabled}
                label={label}
                onChange={onChange}
            />

            {children}
        </>
    );
};

export default WithCheckboxDynamicReturn;

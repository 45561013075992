import { gqlMutations } from 'utilities/gql/gql.util';
import { insertActivityHistoryOneMutationBodyGql } from './activity-history.gql';

export const insertActivityFlagOneName = schema =>
    `insert_${schema}_activity_flag_one`;

export const deleteActivityFlagName = schema =>
    `delete_${schema}_activity_flag`;

export const insertActivityFlagOneMutationBodyGql = ({
    activityId,
    schema,
    userId
}) => {
    return {
        [insertActivityFlagOneName(schema)]: {
            __args: {
                object: { activity_id: activityId, user_id: userId }
            },
            id: true
        }
    };
};

export const deleteActivityFlagMutationBodyGql = ({
    activityId,
    schema,
    userId
}) => {
    return {
        [deleteActivityFlagName(schema)]: {
            __args: {
                where: {
                    activity_id: { _eq: activityId },
                    user_id: { _eq: userId }
                }
            },
            returning: {
                id: true
            }
        }
    };
};

export const flagActivityMutationGql = ({
    activityId,
    color = 'yellow',
    message = 'flagged the activity',
    schema,
    userId
}) => {
    return gqlMutations({
        mutations: [
            insertActivityFlagOneMutationBodyGql({
                activityId,
                schema,
                userId
            }),
            insertActivityHistoryOneMutationBodyGql({
                activityId,
                color,
                message,
                schema,
                userId
            })
        ],
        name: 'flagActivity'
    });
};

export const unflagActivityMutationGql = ({
    activityId,
    color = 'yellow',
    message = 'unflagged the activity',
    schema,
    userId
}) => {
    return gqlMutations({
        mutations: [
            deleteActivityFlagMutationBodyGql({
                activityId,
                schema,
                userId
            }),
            insertActivityHistoryOneMutationBodyGql({
                activityId,
                color,
                message,
                schema,
                userId
            })
        ],
        name: 'unflagActivity'
    });
};

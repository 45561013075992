import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import DropdownItem from 'components/DropdownItem';
import Input from 'components/Input';
import InputWithLabel from 'components/InputWithLabel';
import DropdownMenu from 'components/DropdownMenu';
import Select from 'components/Select';

import useConstants from './hooks/useTranslation';
import usePopperState from 'hooks/usePopperState';

import styles from './styles.module.scss';

import arrayRemove from 'utilities/arrayRemove';
import isFunction from 'utilities/isFunction';

const DropdownMultiSelect = forwardRef((props, ref) => {
    const { SELECT } = useConstants();

    const {
        className = '',
        isRequired = false,
        items = [],
        label = '',
        onChange,
        onSubmit,
        onValid,
        placeholder = SELECT,
        returnObject = false,
        selected = []
    } = props;

    const errorMessage = useRef('');

    const [selectedLabel, setSelectedLabel] = useState([]);
    const [selectedIndexList, setSelectedIndexList] = useState([]);
    useEffect(() => {
        if (placeholder !== SELECT) {
            setSelectedLabel([placeholder]);
        }
    },[setSelectedLabel, SELECT, placeholder]);

    // Note - this component does not have a UE that handles updates
    // Keys of components must handle updates unless dependencies are added to UE

    // useEffect(() => {
    //     if (selected.length !== selectedIndexList.length) {
    //         const newSelectedIndexArray = selected.reduce(
    //             (accumulator, selection) => {
    //                 const foundValue = items.findIndex(item => item.value === selection)
    //                 if (foundValue >= 0) {
    //                         accumulator.push(foundValue)
    //                 }
    //                 return accumulator;
    //             }, []
    //         );

    //         const newIndexArray = [...new Set([...newSelectedIndexArray, ...selectedIndexList])]; //[23, 0]

    //         const newLabelArray = [];
    //         for (const i of newIndexArray){
    //             newLabelArray.push(items[i]?.label);
    //         }

    //         setSelectedIndexList(newIndexArray);
    //         setSelectedLabel(newLabelArray);
    //     }
    // }, [items, selected, selectedIndexList])

    // initializes dropdown field
    useEffect(() => {
        if (Array.isArray(selected) && selected.length > 0 && items.length > 0) {
            const newSelectedIndexArray = selected
                .map(selection => items.findIndex(item => item.value === selection))
                .filter(index => index >= 0);

            setSelectedIndexList(newSelectedIndexArray);
            setSelectedLabel(newSelectedIndexArray.map(index => items[index]?.label));
        }
    }, [items, selected]);

    const { handleClick, isOpen, onClose, referenceElement } = usePopperState();

    const handleChange = useCallback(
        (indexArray) => {
            const valuesArray = indexArray.map(i => items[i]?.value);

            if (isFunction(onChange)) {
                onChange(valuesArray);
            }
        },
        [onChange, items]
    );

    const handleBlur = useCallback(
        event => {
            if (
                !event?.relatedTarget?.classList.contains(styles.dropdownMenu)
            ) {
                onClose();
            }
        },
        [onClose]
    );

    const handleDropdownItemClick = useCallback(
        index => () => {
            let newSelectedIndexList = [];
            let newSelectedLabelList = [];

            if (selectedIndexList.includes(index)) {
                newSelectedIndexList = arrayRemove(selectedIndexList, index);
                newSelectedLabelList = arrayRemove(selectedLabel, items[index]?.label);
            } else {
                newSelectedIndexList = [...selectedIndexList, index];
                newSelectedLabelList = [...selectedLabel, items[index]?.label];
            }

            setSelectedIndexList(newSelectedIndexList);
            setSelectedLabel(newSelectedLabelList);

            const newValuesArray = newSelectedIndexList.map(i =>
                returnObject ? items[i] : items[i]?.value
            );

            onValid?.(newValuesArray);
            handleChange(newSelectedIndexList);
        },
        [handleChange, items, onValid, returnObject, selectedIndexList, selectedLabel]
    );

    useEffect(() => {
        const currentReference = ref?.current;

        const handleSubmit = () => {
            const inputValue = currentReference?.value;

            handleChange({ target: { value: inputValue } });
            onSubmit?.(inputValue, errorMessage.current);
        };

        currentReference?.addEventListener('submit', handleSubmit);

        return () => {
            currentReference?.removeEventListener('submit', handleSubmit);
        };
    }, [errorMessage, handleChange, onSubmit, ref]);

    return (
        <InputWithLabel
            className={`${className} ${styles.dropdown}`}
            isRequired={isRequired}
            text={label}
        >
            <Select
                items={items}
                multiple={true}
                onBlur={handleBlur}
                onClick={handleClick}
                ref={ref}
                value={selectedLabel}
            />

            <Input
                className={`${className} ${styles.input}`}
                isReadOnly={true}
                onBlur={handleBlur}
                onClick={handleClick}
                placeholder={placeholder}
                value={selectedLabel}
            />

            <DropdownMenu
                className={styles.dropdownMenu}
                isOpen={isOpen}
                onClose={onClose}
                referenceElement={referenceElement}
            >
                {items.map(({ label, type, value }, index) => {
                    const isSelected = selectedIndexList.includes(index);
                    return (
                    <DropdownItem
                        isSelected={isSelected}
                        key={`${label}${index}`}
                        onClick={handleDropdownItemClick(index)}
                        text={label}
                        type={type}
                        value={value}
                    />
                )})}
            </DropdownMenu>
        </InputWithLabel>
    );
});

export default DropdownMultiSelect;

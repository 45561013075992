import { useEffect } from 'react';

import PanelBody from 'components/PanelBody';

// import useActivities from './hooks/useActivities';
import useData from './hooks/useData';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import normalizeData from './data';

const Activities = ({ children, hasCompleteItems, hasInProgressItems, hasOverdueItems, hasUpcomingItems, isAll, isFlagged }) => {
    const { useActivities, useQuerySchemas, useSchema, useTenants, useUserId } = useGlobalStateHooks();
    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userId] = useUserId();

    const [activities, setActivities] = useActivities();
    const { loading, error, data } = useData();
    const showGearLoader = () => {
        let message;
        
        if (!isFlagged && !hasCompleteItems && !hasOverdueItems && !hasInProgressItems && !hasUpcomingItems) {
            message = 'There are no flagged activities';
            return {
                message,
                show: true,
            };
        }

        if (
            (!hasCompleteItems && !hasOverdueItems && !hasInProgressItems && !hasUpcomingItems)
        ) {
            message = 'There are no activities for your completion';
            return {
                message,
                show: true,
            };
        }

        return {
            show: false,
        };
    };

    const gearLoader = showGearLoader();

    useEffect(() => {
        if (data && (!activities || !('completed' in activities))) {
            const normalizedData = normalizeData({
                data,
                isFlagged,
                querySchemas,
                schema,
                tenants,
                user: userId
            });
            setActivities(normalizedData);
        }
    // eslint-disable-next-line
    }, [data, isAll, isFlagged, querySchemas, tenants, schema, setActivities, userId]);

    if (error) {
        return `Error! ${error}`;
    }

    return (
        <PanelBody 
            isLoading={loading} 
            isLoadingWithText={gearLoader.show ? gearLoader.message : null}
        >
            {children}
        </PanelBody>
    );
};

export default Activities;

export function useLocalStorage() {
    function getAllStorageKeys() {
        const keys = [];
        for (let i = 0; i < localStorage.length; i++) {
            keys.push(localStorage.key(i));
        }
        return keys;
    }
    function getStorageKeysStartsWith(key = '') {
        return getAllStorageKeys().filter(storageKey =>
            storageKey.startsWith(key)
        );
    }

    return {
        getAllStorageKeys,
        getStorageKeysStartsWith
    };
}

import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';
import { OktaAuth } from '@okta/okta-auth-js';
import { PublicClientApplication } from "@azure/msal-browser";
import { useNavigate, useSearchParams } from 'react-router-dom';

import httpPost from 'utilities/httpPost';

import useConfig from 'hooks/useConfig';

import styles from './styles.module.scss';
import httpGetCors from 'utilities/httpGetCors';

const AuthenticateSSOCallback = ({ className = '' }) => {
    const { API_KEY, AUTH_SSO_URL, AUTH_SAML_VALIDATE, HOME, LOGIN } = useConfig();
    const [searchParams] = useSearchParams();


    const navigate = useNavigate();

    async function auth() {
        const url = `${AUTH_SSO_URL}?username=${localStorage.username}&authenticated=true`;
        const {
            access_token: accessToken,
            refresh_token: refreshToken
        } = await httpPost({key:API_KEY ? API_KEY : '', url});

        if (accessToken && refreshToken) {
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            navigate(HOME);
        }else{
            // navigate(LOGIN);
        }
    }

    if(localStorage.getItem('ssoProvider') === 'okta'){
        const oktaAuth = new OktaAuth({
            clientId: localStorage.getItem('ssoClientId') ? localStorage.getItem('ssoClientId') : '',
            issuer: localStorage.getItem('ssoIssuer') ? localStorage.getItem('ssoIssuer') : '',
            pkce: true,
            redirectUri: window.location.origin + "/login/callback",
        });

        const authenticate = async() => {
            await oktaAuth.handleLoginRedirect();
            const loggedIn = await oktaAuth.getUser();
            if(loggedIn.email === localStorage.userEmail){
                auth();
            }else{
                navigate(LOGIN);
            }
        }
        authenticate();
    }else if(localStorage.getItem('ssoProvider') === 'aad'){
        const msalConfig = {
            auth: {
                authority: `https://login.microsoftonline.com/${localStorage.getItem("aadTenantId")}`,
                clientId: localStorage.getItem('ssoClientId'),
                postLogoutRedirectUri: '/login',
                redirectUri: window.location.origin + "/login/callback",
            },
            scopes: [
                "api.access", "User.Read", "User.ReadBasic.All", "email"
            ]
        };
        
        const msalInstance = new PublicClientApplication(msalConfig);

        async function login(){
            const loginResponse = await msalInstance.loginPopup(msalConfig.scopes);
            const loggedInEmail = loginResponse?.account?.username
            if(loggedInEmail === localStorage.userEmail){
                auth();
            }else{
                navigate(LOGIN);
            }
        }
        login()
    }else if(localStorage.getItem('ssoProvider') === 'aad_saml'){
        const authenticate = async() => {
            const jwt = searchParams.get("jwt");
            const res = await httpGetCors({key: API_KEY ? API_KEY : '', url: `${AUTH_SAML_VALIDATE}?token=${jwt}`});
            const userEmail = res['email'];
            const localEmail = localStorage.userEmail;
            if(userEmail.toString().toLowerCase() === localEmail.toString().toLowerCase()){
                auth();
            }else{
                navigate(LOGIN);
            }
        }
        authenticate();
    }

    return (
        <ContentPanel className={`${className} ${styles.loginPanel}`}>
            <PanelBody>You have been redirected from your SSO provider and your login is being finalized.</PanelBody>
        </ContentPanel>
    );
};

export default AuthenticateSSOCallback;

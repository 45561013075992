import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const CheckPermissions = ({ children, permission }) => {
    const { usePermissions } = useGlobalStateHooks();
    const [permissions] = usePermissions();

    const testPermission = Array.isArray(permission) ? permission : [permission];

    const permitted = testPermission.every(element => permissions.includes(element));

    if (permitted) {
        return (
            <>
                {children}
            </>
        );
    }else{
        return (
            <></>
        );
    }
};

export default CheckPermissions;

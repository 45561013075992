import { useMutation } from '@tanstack/react-query';
import { buildGqlQuery, gqlClient } from 'utilities/gql/gql.util';
import {
    flagActivityMutationGql,
    unflagActivityMutationGql
} from './activity-flags.gql';

export function useFlagActivity() {
    return useMutation({
        mutationFn: async ({ activityId, schema, userId }) => {
            try {
                const gqlQuery = buildGqlQuery({
                    query: () =>
                        flagActivityMutationGql({ activityId, schema, userId })
                });

                const client = gqlClient({
                    accessToken: localStorage.accessToken,
                    url: process.env.REACT_APP_GRAPHQL_URL
                });

                const res = await client.request(gqlQuery);
                return res;
            } catch (error) {
                console.error(error);
                return null;
            }
        }
    });
}

export function useUnflagActivity() {
    return useMutation({
        mutationFn: async ({ activityId, schema, userId }) => {
            try {
                const gqlQuery = buildGqlQuery({
                    query: () =>
                        unflagActivityMutationGql({
                            activityId,
                            schema,
                            userId
                        })
                });

                const client = gqlClient({
                    accessToken: localStorage.accessToken,
                    url: process.env.REACT_APP_GRAPHQL_URL
                });

                const res = await client.request(gqlQuery);
                return res;
            } catch (error) {
                console.error(error);
                return null;
            }
        }
    });
}

import { forwardRef, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import SubmitButton from 'components/SubmitButton';

import isFunction from 'utilities/isFunction';

import useData from 'components/CloseAndCompleteActivityMenuListItem/hooks/useData';
import useTranslation from './hooks/useTranslation';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useConfig from 'hooks/useConfig';

import styles from './styles.module.scss';

const MarkDirectCloseButton = forwardRef(
    ({ activityId, className = '', isDisabled = false, groups, onBeforeClose, onClick, referencedUsers }, ref) => {
        
        const [closedActivity, closeActivity, reset] = useData();
        const { CLOSE } = useTranslation();
        const { useActivities, useUserId } = useGlobalStateHooks();
        
        const [userId] = useUserId();
        const [_activities, setActivities] = useActivities();
        const { HOME } = useConfig();

        const navigate = useNavigate();

        const handleClose = useCallback(async () => {
            if(onBeforeClose) await onBeforeClose();
            let userToClose;
            let closedAssignmentType;
            loop1:
            for (let assignmentType in referencedUsers.current){
                const {users: assignmentUsers, status} = referencedUsers.current[assignmentType];
                if (status === 'inprogress'){
                    for (let user of assignmentUsers){
                        if (user?.lastName === 'Group' && groups.current.includes(user.id)){
                            userToClose = user;
                            closedAssignmentType = assignmentType;
                            break loop1;
                        }else if (user.id === Number(userId)){
                            userToClose = user;
                            closedAssignmentType = assignmentType;
                            break loop1;
                        }
                    }
                }
            }
    
            const dataToSubmit = {
                activityId,
                status: 'complete'
            }
            if (userToClose?.lastName === 'Group'){
                dataToSubmit.groupId = userToClose.id;
            }else{
                dataToSubmit.userId = userToClose.id;
            }
            dataToSubmit.assignmentType = closedAssignmentType;
            dataToSubmit.userName = `${userToClose.firstName} ${userToClose.lastName}`;
            await closeActivity(dataToSubmit);
        }, [activityId, closeActivity, groups, onBeforeClose, referencedUsers, userId]);

        useEffect(() => {
            if (!closedActivity) {
                return;
            }
    
            if (isFunction(onClick)) {
                onClick(closedActivity);
            }

            setActivities({});

            navigate(HOME);
    
            reset();
        }, [HOME, closedActivity, navigate, onClick, reset, setActivities]);

        return (
            <SubmitButton
                className={`${className} ${styles.closeButton}`}
                disabled={isDisabled}
                onClick={handleClose}
                ref={ref}
                text={CLOSE}
            />
        );
    }
);

export default MarkDirectCloseButton;

import { useCallback } from 'react'; 

import AddActivityButton from 'components/AddActivityButton';
import PanelHeader from 'components/PanelHeader';
import PanelTitle from 'components/PanelTitle';
import ViewAllLink from 'components/ViewAllLink';

import ActivitiesViewSelector from 'components/ActivitiesViewSelector';

import useConfig from 'hooks/useConfig';
import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';
import CheckPermissions from 'components/CheckPermissions';

const ActivitiesHeader = ({ className = '', isAll, isFlagged, onFlaggedToggle, onToggle }) => {
    const { ACTIVITIES_ROUTE } = useConfig();
    const { ACTIVITIES } = useTranslation();

    const handleToggle = useCallback(
        isOn => {
            onToggle(!isOn);
        },
        [onToggle]
    );

    const handleFlaggedToggle = useCallback(
        isOn => {
            onFlaggedToggle(!isOn);
        },
        [onFlaggedToggle]
    );

    return (
        <PanelHeader className={`${className} ${styles.activitiesHeader}`}>
            <PanelTitle className={styles.panelTitle} text={ACTIVITIES} />

            <CheckPermissions permission={['activity_view', 'viewAllActivities:read']}>
                <ViewAllLink className={styles.viewAllLink} to={ACTIVITIES_ROUTE} />
            </CheckPermissions>

            <CheckPermissions permission={'activity_add'} >
                <AddActivityButton className={styles.addActivityButton} />
            </CheckPermissions>

            <CheckPermissions permission={'activity_view'}>
                <ActivitiesViewSelector
                    className={styles.activitiesViewSelector}
                    isAll={isAll}
                    isFlagged={isFlagged}
                    onFlaggedToggle={handleFlaggedToggle}
                    onToggle={handleToggle}
                />
            </CheckPermissions>
        </PanelHeader>
    );
};

export default ActivitiesHeader;

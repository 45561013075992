import { useQuery } from '@tanstack/react-query';
import { getAllActivitites } from 'queries/activities.query';
import { getAllCompletedActivitites } from 'queries/activitiesCompleted.query';
import { queryKeys } from 'queries/keys';
import { toDateString } from 'utilities/common.util';

export function useGetActivitiesAtGlanceQuery({
    activityDueDateFrom,
    activityDueDateTo,
    activityStatus,
    isAssignedToMe,
    isFlagged,
    schemas = [],
    userId
}) {
    return useQuery({
        enabled: schemas.length > 0,
        queryFn: () =>
            getAllActivitites({
                activityDueDateFrom,
                activityDueDateTo,
                activityStatus,
                isAssignedToMe,
                isFlagged,
                schemas,
                userId
            }),
        queryKey: queryKeys.actvities.getActivitiesAtGlance({
            activityDueDateFrom,
            activityDueDateTo,
            isAssignedToMe,
            isFlagged,
            schemas,
            status: activityStatus,
            userId
        })
    });
}

export function useGetActivitiesCompletedAtGlanceQuery({
    activityCompletedDateFrom,
    activityCompletedDateTo,
    activityStatus,
    isAssignedToMe,
    isFlagged,
    schemas = [],
    userId
}) {
    return useQuery({
        enabled: schemas.length > 0,
        queryFn: () =>
            getAllCompletedActivitites({
                activityCompletedDateFrom,
                activityCompletedDateTo,
                activityStatus,
                isAssignedToMe,
                isFlagged,
                schemas,
                userId
            }),
        queryKey: queryKeys.actvities.getActivitiesCompletedAtGlance({
            activityCompletedDateFrom,
            activityCompletedDateTo,
            isAssignedToMe,
            isFlagged,
            schemas,
            status: activityStatus,
            userId
        })
    });
}

export function useGetActivitiesAtGlance({
    isAssignedToMe,
    isFlagged,
    schemas = [],
    userId
}) {
    const { data: completedActivities = [], isLoading: completedIsLoading } =
        useGetActivitiesCompletedAtGlanceQuery({
            activityCompletedDateFrom: toDateString(
                new Date(new Date().setDate(new Date().getDate() - 90 ))
            ),
            activityCompletedDateTo: toDateString(
                new Date(new Date().setDate(new Date().getDate() + 90))
            ),
            activityStatus: 'completed',
            isAssignedToMe,
            isFlagged,
            schemas,
            userId
        });

    const { data: pendingActivities = [], isLoading: pendingIsLoading } =
        useGetActivitiesAtGlanceQuery({
            activityStatus: 'pending',
            isAssignedToMe,
            isFlagged,
            schemas,
            userId
        });

    const { data: upcomingActivities = [], upcomingIsLoading } =
        useGetActivitiesAtGlanceQuery({
            activityDueDateFrom: toDateString(
                new Date(new Date().setDate(new Date().getDate() - 30))
            ),
            activityDueDateTo: toDateString(
                new Date(new Date().setDate(new Date().getDate() + 90))
            ),
            activityStatus: 'upcoming',
            isAssignedToMe,
            isFlagged,
            schemas,
            userId
        });

    const { data: overdueActivities = [], overdueIsLoading } =
        useGetActivitiesAtGlanceQuery({
            activityStatus: 'overdue',
            isAssignedToMe,
            isFlagged,
            schemas,
            userId
        });

    return {
        isLoading:
            completedIsLoading ||
            pendingIsLoading ||
            upcomingIsLoading ||
            overdueIsLoading,
        result: {
            completed: completedActivities.length,
            overdue: overdueActivities.length,
            pending: pendingActivities.length,
            upcoming: upcomingActivities.length
        }
    };
}

import {
    getActivityAssigneesFilterName,
    getActivityEntityFilterName,
    getActivityReviewersFilterName,
    getActivityStatusFilterName,
    getActivityTypeFilterName,
    getRecurrenceTypeFilterName
} from 'queries/activities-filters.gql';

export const sortCompletedActivities = activities => {
    return activities.sort((a, b) => {
        if (a.sortDate > b.sortDate) return 1;
        if (a.sortDate < b.sortDate) return -1;
        if (a.type > b.type) return 1;
        if (a.type < b.type) return -1;
        if (a.text > b.text) return 1;
        if (a.text < b.text) return -1;
        return 0;
    });
};

export const sortOverdueActivities = activities => {
    return activities.sort((a, b) => {
        if (a.sortDate > b.sortDate) return 1;
        if (a.sortDate < b.sortDate) return -1;
        if (a.type > b.type) return 1;
        if (a.type < b.type) return -1;
        if (a.text > b.text) return 1;
        if (a.text < b.text) return -1;
        return 0;
    });
};

export const sortInProgressActivities = activities => {
    return activities.sort((a, b) => {
        if (a.sortDate > b.sortDate) return 1;
        if (a.sortDate < b.sortDate) return -1;
        if (a.type > b.type) return 1;
        if (a.type < b.type) return -1;
        if (a.text > b.text) return 1;
        if (a.text < b.text) return -1;
        return 0;
    });
};

export const sortUpcomingActivities = activities => {
    return activities.sort((a, b) => {
        if (a.sortDate > b.sortDate) return 1;
        if (a.sortDate < b.sortDate) return -1;
        if (a.type > b.type) return 1;
        if (a.type < b.type) return -1;
        if (a.text > b.text) return 1;
        if (a.text < b.text) return -1;
        return 0;
    });
};

export function isActivityActive({
    assigned,
    isHierarchical,
    reviewer,
    userId
}) {
    return (
        (!isHierarchical &&
            assigned.some(({ id }) => id === userId) &&
            assigned.filter(x => x.id === userId)[0]?.groupStatus !==
                'complete' &&
            assigned.filter(x => x.id === userId)[0]?.status !== 'complete') ||
        (isHierarchical &&
            assigned.some(({ id }) => id === userId) &&
            (assigned.filter(x => x.id === userId)[0].assignmentNumber === 1 ||
                assigned
                    .filter(
                        y =>
                            y.assignmentNumber <
                            assigned.filter(x => x.id === userId)[0]
                                .assignmentNumber
                    )
                    .every(x => x.groupStatus === 'complete')) &&
            assigned.filter(x => x.id === userId)[0]?.groupStatus !==
                'complete' &&
            assigned.filter(x => x.id === userId)[0]?.status !== 'complete') ||
        (assigned.every(x => x.groupStatus === 'complete') &&
            reviewer.some(({ id }) => id === userId)) ||
        (assigned.every(x => x.groupStatus === 'complete') &&
            reviewer.every(x => x.groupStatus === 'complete') &&
            assigned.some(({ id }) => id === userId))
    );
}

export function getEntitiesName(activityEntities = []) {
    return activityEntities.map(
        activityEntitie => activityEntitie?.entity?.name
    );
}

export function getActivityRecurrences(activityRecurrences = []) {
    return activityRecurrences.map(
        activityRecurrence =>
            activityRecurrence?.activity_recurrence?.recurrence_type
    );
}

export function getUserGroups({
    groupCompletionRate,
    querySchemas,
    schema,
    userActivities
}) {
    const groups = userActivities.reduce((accumulator, user) => {
        const {
            assignment_type: group,
            status,
            tenant_user: userDetails,
            group: userGroup
        } = user;

        if (userDetails || userGroup) {
            const { id } = userDetails ? userDetails : userGroup;
            if (querySchemas.length > 0 && querySchemas[0] !== schema) {
                for (const tenant of querySchemas) {
                    const userArray =
                        userDetails &&
                        `users_${tenant.value}` in userDetails &&
                        userDetails[`users_${tenant.value}`].length > 0
                            ? userDetails[`users_${tenant.value}`][0]
                            : userGroup
                            ? {
                                  first_name: userGroup.name,
                                  last_name: 'Group',
                                  profile_photo: ''
                              }
                            : {
                                  first_name: '',
                                  last_name: '',
                                  profile_photo: ''
                              };
                    const {
                        first_name: firstName,
                        last_name: lastName,
                        profile_photo: profilePhoto
                    } = userArray;

                    const values = {
                        firstName,
                        id,
                        lastName,
                        profilePhoto,
                        status
                    };
                    if (values.firstName !== '') {
                        if (
                            typeof accumulator === 'object' &&
                            group in accumulator
                        ) {
                            accumulator[group].users.push(values);
                        } else if (typeof accumulator === 'object') {
                            accumulator[group] = {
                                users: [values]
                            };
                        } else {
                            accumulator = {};
                            accumulator[group] = {
                                users: [values]
                            };
                        }
                    }
                }
            } else {
                const userArray =
                    userDetails &&
                    `users_${schema}` in userDetails &&
                    userDetails[`users_${schema}`].length > 0
                        ? userDetails[`users_${schema}`][0]
                        : userGroup
                        ? {
                              first_name: userGroup.name,
                              last_name: 'Group',
                              profile_photo: ''
                          }
                        : {
                              first_name: '',
                              last_name: '',
                              profile_photo: ''
                          };

                const {
                    first_name: firstName,
                    last_name: lastName,
                    profile_photo: profilePhoto
                } = userArray;

                const values = {
                    firstName,
                    id,
                    lastName,
                    profilePhoto,
                    status
                };
                if (typeof accumulator === 'object' && group in accumulator) {
                    accumulator[group].users.push(values);
                } else if (typeof accumulator === 'object') {
                    accumulator[group] = { users: [values] };
                } else {
                    accumulator = {};
                    accumulator[group] = { users: [values] };
                }
            }
        }
        return accumulator;
    }, {});
    const userGroups = Object.keys(groups)
        .sort()
        .reduce(function (acc, key) {
            acc[key] = groups[key];
            return acc;
        }, {});
    for (const [index, [key]] of Object.entries(Object.entries(userGroups))) {
        const group = userGroups[key].users;
        var groupProgress;
        if (groupCompletionRate && groupCompletionRate[index]) {
            if (
                typeof groupCompletionRate[index] === 'string' &&
                groupCompletionRate[index] === 'all'
            ) {
                groupProgress = group.some(
                    ({ status }) => status !== 'complete'
                );
            } else if (
                typeof groupCompletionRate[index] === 'number' &&
                groupCompletionRate[index] > 1
            ) {
                const complete = group.filter(
                    ({ status }) => status === 'complete'
                );
                groupProgress =
                    (complete.length / group.length) * 100 >=
                    groupCompletionRate['index'];
            } else {
                groupProgress = group.every(
                    ({ status }) => status !== 'complete'
                );
            }
            userGroups[key]['status'] = groupProgress
                ? 'inprogress'
                : 'complete';
        }
    }

    return userGroups;
}

function getUsers({
    querySchemas = [],
    schema,
    userActivities = [],
    userGroups = {}
}) {
    const users = userActivities
        .filter(
            userActivity =>
                userActivity.status && userActivity.status !== 'removed'
        )
        .reduce(
            (
                accumulator,
                {
                    assignment_type: assignmentType,
                    status,
                    tenant_user: tenantUser,
                    group
                }
            ) => {
                const groupStatus =
                    userGroups[assignmentType]?.status === 'complete'
                        ? userGroups[assignmentType]?.status
                        : status;
                if (tenantUser) {
                    const id = tenantUser.id;

                    var users;
                    if (querySchemas.length > 1) {
                        for (const tenant of querySchemas) {
                            if (
                                tenantUser[`users_${tenant}`] &&
                                tenantUser[`users_${tenant}`].length > 0
                            ) {
                                users = tenantUser[`users_${tenant}`];
                            }
                        }
                    } else {
                        users = tenantUser[`users_${schema}`];
                    }

                    if (!users || users.length === 0) {
                        for (const key of Object.keys(tenantUser)) {
                            if (
                                key.startsWith('users_') &&
                                tenantUser[key].length > 0
                            ) {
                                users = tenantUser[key];
                            }
                        }
                    }

                    const {
                        first_name: firstName,
                        last_name: lastName,
                        profile_photo: profilePhoto
                    } = users && users.length > 0
                        ? users[0]
                        : {
                              first_name: '',
                              last_name: '',
                              profile_photo: ''
                          };

                    const assignmentNumber =
                        assignmentType === 'reviewer'
                            ? 0
                            : Number(assignmentType.split('-')[1]);

                    if (assignmentType !== 'reviewer') {
                        accumulator.assigned.push({
                            assignmentNumber,
                            firstName,
                            groupStatus,
                            id,
                            lastName,
                            profilePhoto,
                            status
                        });
                    } else {
                        accumulator.reviewer.push({
                            assignmentNumber,
                            firstName,
                            groupStatus,
                            id,
                            lastName,
                            profilePhoto,
                            status
                        });
                    }

                    return accumulator;
                } else if (group) {
                    const id = group.id;

                    const { name: firstName } = group;

                    const assignmentNumber =
                        assignmentType === 'reviewer'
                            ? 0
                            : Number(assignmentType.split('-')[1]);

                    if (assignmentType !== 'reviewer') {
                        accumulator.assigned.push({
                            assignmentNumber,
                            firstName,
                            groupStatus,
                            id,
                            lastName: 'Group',
                            profilePhoto: '',
                            status: ''
                        });
                    } else {
                        accumulator.reviewer.push({
                            assignmentNumber,
                            firstName,
                            groupStatus,
                            id,
                            lastName: 'Group',
                            profilePhoto: '',
                            status: ''
                        });
                    }

                    return accumulator;
                } else {
                    return accumulator;
                }
            },
            { assigned: [], reviewer: [] }
        );

    return users;
}

export function getCompletable({ assigned = [], reviewer = [], userId }) {
    const assignedCompletableArray = assigned.map(x => {
        if (x?.groupStatus !== 'complete') {
            return x;
        } else {
            return null;
        }
    });
    const reviewerCompletableArray = reviewer.map(x => {
        if (x?.groupStatus !== 'complete') {
            return x;
        } else {
            return null;
        }
    });
    const completableArray = [
        ...assignedCompletableArray,
        ...reviewerCompletableArray
    ];
    const nullRemovedCompletableArray = completableArray.filter(function (x) {
        return x !== null && x !== undefined;
    });
    const assignmentNumbers = nullRemovedCompletableArray.map(x => {
        return x?.assignmentNumber;
    });
    const completableAssignmentNumber = assignmentNumbers.includes(0)
        ? 0
        : Math.max(...assignmentNumbers);
    const userAssignmentNumberArrayWithNulls = nullRemovedCompletableArray.map(
        x => {
            if (x?.id === userId) {
                return x?.assignmentNumber;
            } else {
                return null;
            }
        }
    );
    const userAssignmentNumberArray = userAssignmentNumberArrayWithNulls.filter(
        function (x) {
            return x !== null && x !== undefined;
        }
    );
    const userAssignmentNumber = userAssignmentNumberArray[0];
    const completable = completableAssignmentNumber === userAssignmentNumber;

    return completable;
}

export function computeUsersData({
    groupCompletionRate,
    querySchemas,
    schema,
    userActivities,
    userId
}) {
    const userGroups = getUserGroups({
        groupCompletionRate,
        querySchemas,
        schema,
        userActivities
    });

    const users = getUsers({
        querySchemas,
        schema,
        userActivities,
        userGroups
    });

    const usersSortOrder = ['inprogress', 'complete'];
    const individualStatusSortedAssigned = [...users.assigned].sort(function (
        a,
        b
    ) {
        return (
            usersSortOrder.indexOf(a.status) - usersSortOrder.indexOf(b.status)
        );
    });
    const assignmentSortedAssigned = [...individualStatusSortedAssigned].sort(
        function (a, b) {
            return a.assignmentNumber - b.assignmentNumber;
        }
    );
    const assigned = [...assignmentSortedAssigned].sort(function (a, b) {
        return (
            usersSortOrder.indexOf(a.groupStatus) -
            usersSortOrder.indexOf(b.groupStatus)
        );
    });

    const reviewer = users.reviewer.sort(function (x, y) {
        return x.id === userId ? -1 : y.id === userId ? 1 : 0;
    });

    return {
        assigned,
        reviewer
    };
}

export const activitiesFilterDataTransformer =
    (schemas = []) =>
    (data = {}) => {
        const acumulators = schemas.reduce(
            (filters, schema) => {
                const {
                    [getRecurrenceTypeFilterName(schema)]: recurrenceTypes = [],
                    [getActivityStatusFilterName(schema)]: activityStatus = [],
                    [getActivityTypeFilterName(schema)]: activityType = [],
                    [getActivityEntityFilterName(schema)]:
                        activityEntities = [],
                    [getActivityAssigneesFilterName(schema)]:
                        activityAssignees = [],
                    [getActivityReviewersFilterName(schema)]:
                        activityReviewers = []
                } = data;

                recurrenceTypes.forEach(recurrenceType => {
                    const { count, recurrence_type } = recurrenceType;
                    filters.recurrenceTypes[recurrence_type] =
                        (filters.recurrenceTypes[recurrence_type] ?? 0) + count;
                });

                activityStatus.forEach(status => {
                    const { count, status: activityStatus } = status;
                    filters.activityStatus[activityStatus] =
                        (filters.activityStatus[activityStatus] ?? 0) + count;
                });

                activityType.forEach(type => {
                    const { count, name } = type;
                    filters.activityTypes[name] =
                        (filters.activityTypes[name] ?? 0) + count;
                });

                activityEntities.forEach(entity => {
                    const { count, name } = entity;
                    filters.activityEntities[name] =
                        (filters.activityEntities[name] ?? 0) + count;
                });

                activityAssignees.forEach(entity => {
                    const { count, full_name: name } = entity;
                    filters.activityAssignees[name] =
                        (filters.activityAssignees[name] ?? 0) + count;
                });

                activityReviewers.forEach(entity => {
                    const { count, full_name: name } = entity;
                    filters.activityReviewers[name] =
                        (filters.activityReviewers[name] ?? 0) + count;
                });

                return filters;
            },
            {
                activityAssignees: {},
                activityEntities: {},
                activityReviewers: {},
                activityStatus: {},
                activityTypes: {},
                recurrenceTypes: {}
            }
        );

        return Object.entries(acumulators).reduce((filters, [key, value]) => {
            filters[key] = Object.entries(value).map(([name, count]) => ({
                count,
                name
            }));

            return filters;
        }, {});
    };

    const resolvePolicies = activityPolicies =>
        activityPolicies.reduce((accumulator, { document: policy, section }) => {
            if (section == null){
                accumulator.push({description: policy?.description, id: policy?.id, name: policy?.name, riskLevel: policy?.risk_label});
            }else{
                accumulator.push({description: section?.description, id: policy?.id, name: section?.name, riskLevel: section?.riskLevel})
            }
            return accumulator;
        }, []);

    export const getPolicyReferences = ({activityPolicies = [], user_activities = []}) => {
        if(user_activities.length > 0){
            return activityPolicies.length > 0 ? resolvePolicies(activityPolicies) : null
        }else{
            return activityPolicies
        }
    }
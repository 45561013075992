import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const queryUsersAndEntities = tenantSchema => gql`
    query getUsersAndEntities {
        ${tenantSchema}_document {
            name
            id
        }
        ${tenantSchema}_case_type {
            id
            name
            form_id
        }
        ${tenantSchema}_user(where: {_or: {registration_status: {_neq: "disabled"}, employee_status: {_neq: "disabled"}}}) {
            tenant_user_id
            first_name
            last_name
        }
        ${tenantSchema}_admin_settings (where: {setting_name: {_eq: "case_type_default"}}) {
            id
            setting
            setting_name
        }
    }
`;

const useDataQuery = (providedSchema) => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    return useQuery(queryUsersAndEntities(usedSchema), { fetchPolicy: 'no-cache' });
};

export default useDataQuery;

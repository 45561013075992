import normalizeListData from "utilities/normalizeListData";

const schemaData = ({ data, schema }) =>
    data[`${schema}_group`].reduce(
        (accumulator, group) => {
            const users = group?.group_users.reduce((accum, user) => {
                accum.push(user?.tenant_user_id);
                return accum;
            }, []);
            accumulator.push({
                label: group.name,
                type: 'group',
                users,
                value: group.id
            });

            return accumulator;
        }, []);

const normalizeGroupData = ({ data, schema, tenants }) => normalizeListData({ data, schema, schemaData, tenants });

export default normalizeGroupData;